import React from "react";
import Button from "./button";

interface Notification {
  id: string;
  title: string;
  description: string;
}

interface BuyerSection {
  title: string;
  notifications: Notification[];
}

interface NotificationsProps {
  buyerSections: BuyerSection[];
}

const NotificationCheckbox: React.FC<{ id: string }> = ({ id }) => (
  <label
    className="relative flex cursor-pointer items-center rounded-full p-3"
    data-ripple-dark="true"
  >
    <input
      type="checkbox"
      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded border-2 border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-purple-600 checked:bg-purple-600 checked:before:bg-purple-600 hover:before:opacity-10"
      id={id}
    />
    <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3.5 w-3.5"
        viewBox="0 0 20 20"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  </label>
);

const Notifications: React.FC<NotificationsProps> = ({ buyerSections }) => {
  return (
    <div className="bg-gray-800 bg-opacity-50 px-4 pt-8 pb-12 md:py-12 md:px-10 xl:px-24 w-full rounded-2xl">
      <div className="text-center text-white font-semibold text-xl md:text-2xl">
        Manage Notifications
      </div>
      <div className="text-center text-gray-400 font-normal text-base mt-2">
        Choose which notifications you want to receive on your email.
      </div>
      <form action="" className="w-full">
        {buyerSections.map((buyerSection, index) => (
          <div key={index}>
            <div className="flex left-0 text-lg font-medium text-white mt-8">
              {buyerSection.title}
            </div>
            {buyerSection.notifications.map((notification) => (
              <div key={notification.id} className="flex items-center w-full justify-between border-2 rounded-md border-gray-800 py-4 px-6 mt-4">
                <div className="">
                  <div className="text-white text-base font-medium">
                    {notification.title}
                  </div>
                  <div className="text-sm text-gray-300 font-normal mt-1">
                    {notification.description}
                  </div>
                </div>
                <NotificationCheckbox id={notification.id} />
              </div>
            ))}
          </div>
        ))}
        <div className="inline-flex w-full justify-center mt-14">
          <Button variant="secondary" variantType="button" variantSize="block" type="button">
            Update Preferences
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Notifications;