import React from 'react';

const IconWallerConnect = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_499_13656)">
                <path d="M4.91331 7.49901C8.82719 3.667 15.1728 3.667 19.0868 7.49901L19.5578 7.96019C19.7534 8.15181 19.7534 8.46244 19.5578 8.65406L17.9464 10.2317C17.8486 10.3275 17.6899 10.3275 17.5921 10.2317L16.9439 9.59706C14.2134 6.92373 9.78656 6.92373 7.05613 9.59706L6.36194 10.2767C6.26413 10.3725 6.1055 10.3725 6.00763 10.2767L4.39631 8.69906C4.20056 8.50744 4.20056 8.19681 4.39631 8.00519L4.91331 7.49901ZM22.4191 10.7618L23.8533 12.1658C24.0489 12.3574 24.0489 12.6681 23.8533 12.8597L17.3868 19.191C17.1911 19.3826 16.8738 19.3826 16.6781 19.191L12.0886 14.6975C12.0397 14.6496 11.9604 14.6496 11.9114 14.6975L7.32206 19.191C7.12638 19.3826 6.80906 19.3826 6.61338 19.191L0.14677 12.8596C-0.0489234 12.668 -0.0489234 12.3574 0.14677 12.1658L1.58087 10.7616C1.77656 10.5701 2.09385 10.5701 2.28955 10.7616L6.87913 15.2552C6.928 15.3031 7.00731 15.3031 7.05625 15.2552L11.6456 10.7616C11.8413 10.5701 12.1586 10.5701 12.3543 10.7616L16.9438 15.2552C16.9928 15.3031 17.0721 15.3031 17.121 15.2552L21.7104 10.7618C21.9062 10.5701 22.2234 10.5701 22.4191 10.7618Z" fill="#3396FF"/>
            </g>
            <defs>
                <clipPath id="clip0_499_13656">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconWallerConnect;
