import * as React from "react";
import { MarketplaceCollections } from "./marketplaceCollections";
import { MarketplaceCategoriesNav } from "./marketplaceCategoriesNav";
import Backgrounds from "./backgrounds";
import Nav from "./nav";
import Containers from "../new-component/containers";
import { Footer } from "../new-component/footer";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import {useGetCollections} from "../hooks/useGetCollections";
import ItemInformation from "../new-component/ItemInformation";



export const MarketplaceHome = () => {

    const popularCollections = useGetCollections(0, 10, 'viewed,DESC');
    const newCollections = useGetCollections(0, 10, 'createdAt,DESC');

    return (popularCollections && newCollections &&
        <Backgrounds>
            <Containers>

                <Nav/>

                {/*TODO show only when someone wants to list their Real Estate*/}
                {/*<Alert*/}
                {/*    title="We need to verify your identity"*/}
                {/*    description="Before you can start investing in real estate, you have to verify your identity."*/}
                {/*    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">*/}
                {/*        <path fillRule="evenodd" clipRule="evenodd"*/}
                {/*              d="M9.40087 3.0034C10.5554 1.00229 13.4435 1.00229 14.598 3.0034L21.9523 15.7509C23.1062 17.7509 21.6627 20.2501 19.3538 20.2501H4.6451C2.33612 20.2501 0.892695 17.7509 2.04654 15.7509L9.40087 3.0034ZM11.9996 8.25C12.4138 8.25 12.7496 8.58579 12.7496 9V12.75C12.7496 13.1642 12.4138 13.5 11.9996 13.5C11.5854 13.5 11.2496 13.1642 11.2496 12.75V9C11.2496 8.58579 11.5854 8.25 11.9996 8.25ZM11.9996 16.5C12.4138 16.5 12.7496 16.1642 12.7496 15.75C12.7496 15.3358 12.4138 15 11.9996 15C11.5854 15 11.2496 15.3358 11.2496 15.75C11.2496 16.1642 11.5854 16.5 11.9996 16.5Z"*/}
                {/*              fill="#FCD34D"/>*/}
                {/*        </svg>}*/}
                {/*    buttonAction=""*/}
                {/*    buttonText="Verify Identity"/>*/}

                <MarketplaceCategoriesNav key="marketplace-categories-nav"/>

                <div className="tab-content pt-4">
                    <MarketplaceCollections key="market-place-popular-collections" title="Popular Collections" id="nav-container-popular" items={popularCollections} type="text-under"/>
                    <MarketplaceCollections key="market-place-new-collections" title="New Collections" id="nav-container-new" items={newCollections} type="text-under"/>
                </div>

                {/* <div className="">
                    <ItemInformation
                        title="Hotels Coming Soon!"
                        text="We’re currently working on bringing hotels here as well. Stay tuned!"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <path d="M20.6809 31.6796C19.3031 31.5607 17.9086 31.5 16.5 31.5H15C10.0294 31.5 6 27.4706 6 22.5C6 17.5294 10.0294 13.5 15 13.5H16.5C17.9086 13.5 19.3031 13.4393 20.6809 13.3204M20.6809 31.6796C21.1866 33.603 21.8474 35.4635 22.6493 37.2469C23.1442 38.3476 22.7684 39.6655 21.7232 40.269L20.4106 41.0268C19.3078 41.6635 17.8912 41.2612 17.3568 40.1053C16.1036 37.3947 15.1308 34.5278 14.4757 31.5419M20.6809 31.6796C19.9104 28.749 19.5 25.6724 19.5 22.5C19.5 19.3276 19.9104 16.251 20.6809 13.3204M20.6809 31.6796C26.9999 32.2248 32.9689 33.9943 38.3494 36.7498M20.6809 13.3204C26.9999 12.7752 32.9689 11.0057 38.3494 8.25017M38.3494 8.25017C38.114 7.49189 37.8603 6.74166 37.5888 6M38.3494 8.25017C39.4194 11.6965 40.1115 15.3092 40.3772 19.0398M38.3494 36.7498C38.114 37.5081 37.8603 38.2583 37.5888 39M38.3494 36.7498C39.4194 33.3035 40.1115 29.6907 40.3772 25.9602M40.3772 19.0398C41.3688 19.8653 42 21.1089 42 22.5C42 23.8911 41.3688 25.1347 40.3772 25.9602M40.3772 19.0398C40.4586 20.1826 40.5 21.3365 40.5 22.5C40.5 23.6635 40.4586 24.8174 40.3772 25.9602" stroke="#A78BFA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </ItemInformation>
                </div> */}

            </Containers>
            <Footer/>
        </Backgrounds>
    );
};
