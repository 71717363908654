import React from "react";
import ItemList from "../new-component/itemList";
import ItemComponent from "../new-component/itemComponent"
import Search from "../new-component/searchForm";
import Select from "../new-component/select";

const name = [
  { id: 1, name: '0x50Ba...cA3A' },
  { id: 2, name: '0x60Ba...cA3A' },
  { id: 3, name: '0x70Ba...cA3A' },
  { id: 4, name: '0x80Ba...cA3A' },
];

const variant = [
  { id: 1, name: 'Price low to high' },
  { id: 2, name: 'Price high to low' },
  { id: 3, name: 'Recently listed' },
  { id: 4, name: 'Most Viewed' },
];

const OwnedAssets: React.FC = () => {
  return (
    <div className="">
      <div className="flex items-center flex-col md:flex-row gap-4">
        <Search/>
        <div className="flex items-center w-full justify-between">
          {/*<Select variant={name}/>*/}
          {/*<Select variant={variant}/>*/}
        </div>
      </div>
      <ItemList
        buttonText="Show More"
        variantSize="deflaut"
        initialItemsToShow={4}
        itemData={5}
        showNumConfig={{ smallScreen: 4, mediumScreen: 6, largeScreen: 8 }}
        sort=""
      />
    </div>
  );
};

export default OwnedAssets;
