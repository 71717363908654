import React from 'react';

const SearchForm: React.FC = () => {
  return (
    <form className="bg-gray-900 border border-gray-700 rounded-md p-1.5 w-full md:w-1/2 xl:w-1/3 2xl:w-64">
      <div className="flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="#6B7280"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 3.99994C5.96243 3.99994 3.5 6.46237 3.5 9.49994C3.5 12.5375 5.96243 14.9999 9 14.9999C10.519 14.9999 11.893 14.3852 12.8891 13.389C13.8852 12.3929 14.5 11.0189 14.5 9.49994C14.5 6.46237 12.0376 3.99994 9 3.99994ZM2 9.49994C2 5.63395 5.13401 2.49994 9 2.49994C12.866 2.49994 16 5.63395 16 9.49994C16 11.1625 15.4197 12.6905 14.4517 13.891L17.7803 17.2196C18.0732 17.5125 18.0732 17.9874 17.7803 18.2803C17.4874 18.5732 17.0126 18.5732 16.7197 18.2803L13.3911 14.9517C12.1906 15.9196 10.6625 16.4999 9 16.4999C5.13401 16.4999 2 13.3659 2 9.49994Z"
          />
        </svg>
        <div className="w-full">
          <input
            id="search-input"
            type="search"
            placeholder="Search"
            name="search"
            autoComplete="off"
            required
            className="searchbox__input w-full outline-none text-sm font-normal text-gray-500"
            style={{ background: 'none' }}
          />
        </div>
        <button type="reset" id="myBlock" className="hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="#6B7280"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5 18.5C14.9183 18.5 18.5 14.9183 18.5 10.5C18.5 6.08172 14.9183 2.5 10.5 2.5C6.08172 2.5 2.5 6.08172 2.5 10.5C2.5 14.9183 6.08172 18.5 10.5 18.5ZM8.78033 7.71967C8.48744 7.42678 8.01256 7.42678 7.71967 7.71967C7.42678 8.01256 7.42678 8.48744 7.71967 8.78033L9.43934 10.5L7.71967 12.2197C7.42678 12.5126 7.42678 12.9874 7.71967 13.2803C8.01256 13.5732 8.48744 13.5732 8.78033 13.2803L10.5 11.5607L12.2197 13.2803C12.5126 13.5732 12.9874 13.5732 13.2803 13.2803C13.5732 12.9874 13.5732 12.5126 13.2803 12.2197L11.5607 10.5L13.2803 8.78033C13.5732 8.48744 13.5732 8.01256 13.2803 7.71967C12.9874 7.42678 12.5126 7.42678 12.2197 7.71967L10.5 9.43934L8.78033 7.71967Z"
            />
          </svg>
        </button>
      </div>
    </form>
  );
};


export default SearchForm;
