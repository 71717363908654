import { useEffect, useState } from "react";

export enum ItemState {
  FOR_SELL = "FOR_SELL",
  SOLD_OUT = "SOLD_OUT"
}

export type Attribute = {
  name: string;
  value: string;
}

type Coords = {
  lat: number;
  lng: number;
}

export type Item = {
  id: number;
  tokenId: number;
  name: string;
  description: string;
  collectionName: string;
  attributes: Attribute[];
  price: string;
  usdPrice: string;
  status: ItemState;
  logo: string;
  center: Coords;
  polygon: Coords[];
  collectionPolygon: Coords[];
};

export function useGetItem(tokenId: string | undefined, collectionAddress?: string | undefined): Item {
  const [item, setItem] = useState<Item>();

  useEffect(() => {
    const getItem = async () => {
      fetch(`https://api.qentare.com/v1/collections/${collectionAddress}/items/${tokenId}/info`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setItem(data)
          })
    };

    getItem();
  }, [tokenId, collectionAddress]);

  return item as Item;
}
