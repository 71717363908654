import React from "react";
import Backgrounds from "../components/backgrounds";
import { Footer } from "../new-component/footer";
import ForgotPasswordForms from "../new-component/forgotPasswordForms";

export const ForgotPassword: React.FC = () => {
  return (
    <Backgrounds>
        <ForgotPasswordForms/>
      <Footer/>
    </Backgrounds>
  );
};
