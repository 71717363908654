import * as React from "react";
import Button from "../new-component/button";
import { MarketPlaceHeaderCollections } from "./marketPlaceHeaderCollections";
import { useEffect, useState } from "react";
import { useGetGroupedCollections } from "../hooks/useGetGroupedCollections";

export const MarketplaceCategoriesNav: React.FC = () => {
    const groupedCollections = useGetGroupedCollections();
    const [selectedIndex, setSelectedIndex] = useState(0);

  return (
      <div className="2xl:max-w-7xl 2xl:mx-auto ">
          <div className="flex flex-col gap-2 whitespace-nowrap ">
              <div className="flex gap-3 whitespace-nowrap overflow-x-scroll no-scrollbar">
                  {
                      groupedCollections.map((category, index) => (
                          <>
                            <Button variant='tab' variantSize='block' active={selectedIndex === category.categoryId} onClick={() => setSelectedIndex(category.categoryId)}>{category.categoryName}</Button>
                          </>
                      ))
                  }
              </div>

              <div className="tab-content pt-4">
                  {
                      groupedCollections.map((category, index) => (
                          <div className={selectedIndex === category.categoryId ? "tab-pane tab-active" : "tab-pane hidden"}>
                              <div className="w-full h-full mx-auto transition-all duration-500 ease-linear">
                                  <div className="relative">

                                      <MarketPlaceHeaderCollections id="nav-container-estate" key="nav-container-header" items={1} type="full-width" collections={category.collections}/>

                                      <div id="nav-container-estate" className="h-0"></div>

                                  </div>
                              </div>
                          </div>
                      ))
                  }
              </div>
          </div>

      </div>
  );
};
