import React, { ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
}

const Containers: React.FC<ContainerProps> = ({ children }) => {
  return (
    <div className="container px-4 md:px-8 mx-auto 2xl:max-w-7xl mt-24"> {/*mt-32*/}
      {children}
    </div>
  );
};

export default Containers;
