import { Fragment, useState } from 'react'
import { Transition, Listbox } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import Button from "./button";

interface WalletAddressRowProps {
  walletAddress: string;
}

interface WalletAddressProps {
  tableData: WalletAddressRowProps[];
}

const cryptos = [
  { id: 1, name: 'Ethereum', price: '$12,563.00 USD' },
  { id: 2, name: 'Arbitrum', price: '$6,124.00 USD' },
  { id: 3, name: 'Avalanche', price: '$1,124.00 USD' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const WalletAddress: React.FC<WalletAddressProps> = ({ tableData }) => {
  const [selectedCrypto, setSelectedCrypto] = useState(cryptos[0]);

  return (
    <div className="bg-gray-800 bg-opacity-50 px-4 pt-8 pb-12 md:py-12 md:px-10 xl:px-24 w-full rounded-2xl">
      <div className="text-center text-white font-semibold text-xl md:text-2xl">
        Manage Your Wallet Addresses
      </div>
      <div className="text-center text-gray-400 font-normal text-base mt-2">
        Add a new address or sign out of an already added one.
      </div>
      {tableData.map((rowData, index) => (
        <div className="mt-8 border-2 border-gray-800 rounded-lg p-4 md:flex items-center relative">
          <div className="flex items-center justify-between md:justify-start md:pr-6">
            <div className="text-gray-300 font-medium text-lg">{rowData.walletAddress}</div>
            <div className="flex items-center md:absolute md:right-0">
              <Button variant="icon" variantType="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3127 11.4233C14.5265 14.3574 11.5107 16.0986 8.57661 15.3124C7.60759 15.0528 6.77054 14.5511 6.11082 13.8902L5.79929 13.5786L8.23252 13.5782C8.64673 13.5781 8.98246 13.2423 8.98238 12.8281C8.98231 12.4139 8.64646 12.0781 8.23225 12.0782L3.98881 12.079C3.7899 12.079 3.59914 12.1581 3.45852 12.2987C3.31789 12.4394 3.23891 12.6302 3.23895 12.8291L3.23984 17.071C3.23993 17.4852 3.57579 17.8209 3.99 17.8208C4.40421 17.8207 4.73993 17.4849 4.73984 17.0706L4.73933 14.64L5.04918 14.9499C5.89022 15.7924 6.9579 16.4316 8.18838 16.7613C11.9226 17.7619 15.761 15.5458 16.7616 11.8116C16.8688 11.4115 16.6314 11.0002 16.2313 10.893C15.8312 10.7858 15.4199 11.0232 15.3127 11.4233ZM16.5416 7.70095C16.6823 7.56027 16.7613 7.36947 16.7613 7.17054L16.7606 2.92859C16.7605 2.51438 16.4246 2.17864 16.0104 2.17871C15.5962 2.17878 15.2605 2.51462 15.2606 2.92883L15.261 5.3597L14.9511 5.04989C14.1101 4.20747 13.0422 3.56802 11.8118 3.23835C8.07758 2.23775 4.23923 4.45383 3.23863 8.18809C3.13143 8.58819 3.36886 8.99944 3.76896 9.10665C4.16906 9.21386 4.58031 8.97642 4.68752 8.57632C5.4737 5.64226 8.48955 3.90105 11.4236 4.68723C12.3927 4.94689 13.2297 5.44857 13.8895 6.10955L14.2006 6.42066L11.7686 6.42066C11.3544 6.42066 11.0186 6.75644 11.0186 7.17066C11.0186 7.58487 11.3544 7.92066 11.7686 7.92066H16.0113C16.2102 7.92066 16.401 7.84162 16.5416 7.70095Z" fill="#D1D5DB"/>
                </svg>
              </Button>
              <Button variant="icon" variantType="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.25C3 3.00736 4.00736 2 5.25 2H10.75C11.9926 2 13 3.00736 13 4.25V6.25C13 6.66421 12.6642 7 12.25 7C11.8358 7 11.5 6.66421 11.5 6.25V4.25C11.5 3.83579 11.1642 3.5 10.75 3.5H5.25C4.83579 3.5 4.5 3.83579 4.5 4.25V15.75C4.5 16.1642 4.83579 16.5 5.25 16.5H10.75C11.1642 16.5 11.5 16.1642 11.5 15.75V13.75C11.5 13.3358 11.8358 13 12.25 13C12.6642 13 13 13.3358 13 13.75V15.75C13 16.9926 11.9926 18 10.75 18H5.25C4.00736 18 3 16.9926 3 15.75V4.25Z" fill="#D1D5DB"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 10C6 9.58579 6.33579 9.25 6.75 9.25H16.2955L15.2483 8.30747C14.9404 8.03038 14.9154 7.55616 15.1925 7.24828C15.4696 6.94039 15.9438 6.91543 16.2517 7.19253L18.7517 9.44253C18.9098 9.58476 19 9.78738 19 10C19 10.2126 18.9098 10.4152 18.7517 10.5575L16.2517 12.8075C15.9438 13.0846 15.4696 13.0596 15.1925 12.7517C14.9154 12.4438 14.9404 11.9696 15.2483 11.6925L16.2955 10.75H6.75C6.33579 10.75 6 10.4142 6 10Z" fill="#D1D5DB"/>
                </svg>
              </Button>
            </div>
          </div>
          <Listbox value={selectedCrypto} onChange={setSelectedCrypto}>
            {({ open }) => (
              <>
                <div className="relative pt-4 md:pt-0">
                  <Listbox.Button className="relative w-full text-sm font-normal rounded-md border border-gray-700 bg-gray-800 cursor-pointer px-3 py-2 focus:ring-2 focus:ring-violet-600">
                    <div className="flex gap-3">
                      <span className="block truncate text-gray-50">{selectedCrypto.name}</span>
                      <span className="block truncate mr-5 text-gray-400">{selectedCrypto.price}</span>
                    </div>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className={`h-5 w-5 text-gray-500 transform ${open ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}
                      aria-hidden="true"
                    />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 rounded-md bg-gray-800 z-10 w-full py-1 overflow-auto text-sm">
                      {cryptos.map((crypto) => (
                        <Listbox.Option
                          key={crypto.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-600 rounded-lg' : '',
                              'relative cursor-pointer select-none py-2 pl-2.5 pr-8'
                            )
                          }
                          value={crypto}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={classNames('block truncate', selected ? 'selected-wallet' : '')}>
                                <div className="flex justify-between items-center">
                                  <span className="text-left text-white">{crypto.name}</span>
                                  <span className="text-right text-gray-400">{crypto.price}</span>
                                </div>
                              </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-green-500' : 'text-green-500',
                                    'absolute inset-y-0 right-0 flex items-center pr-1'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>                            
        </div>
      ))}
      <div className="flex justify-center pt-14">
        <Button variant="primary" variantType='button' variantSize='block'>
          Connect Another Address
        </Button>
      </div>
    </div>
  );
};

export default WalletAddress;
