import React, { useState, useRef } from 'react';
import Button from "./button";

type IconVisibility = {
  icon1: boolean;
  icon2: boolean;
  icon3: boolean;
  icon4: boolean;
};

type HeaderProps = {
  text: string;
  description: string;
  img: string;
};

const HeaderCollection: React.FC<HeaderProps> = ({ text, description, img }) => {
  const [iconVisibility, setIconVisibility] = useState<IconVisibility>({
    icon1: true,
    icon2: false,
    icon3: true,
    icon4: false,
  });

  const linkInputRef = useRef<HTMLInputElement | null>(null);

  const toggleIcons = (iconName: keyof IconVisibility) => {
    setIconVisibility((prevVisibility) => ({
      ...prevVisibility,
      [iconName]: !prevVisibility[iconName],
      icon1: iconName === "icon2" ? !prevVisibility.icon1 : prevVisibility.icon1,
      icon3: iconName === 'icon4' ? !prevVisibility.icon3 : prevVisibility.icon3,
    }));
  };

  const copyLink = () => {
    if (linkInputRef.current) {
      linkInputRef.current.select();
      document.execCommand('copy');
      linkInputRef.current.setSelectionRange(0, 0);
      alert('Link copied to clipboard: ' + linkInputRef.current.value);
    }
  };

  return (
    <header className="md:flex md:flex-row-reverse justify-end md:pb-12 pb-16 md:gap-8">
      <div className="flex w-full flex-col text-center md:text-start md:relative lg:flex-row lg:justify-between md:justify-center">
        <div className="flex flex-col justify-center">
          <div className="text-3xl md:text-4xl xl:text-5xl text-white font-semibold">
            {text}
          </div>
          <div className="text-xl md:text-lg xl:text-xl lg:mt-4 text-gray-400 mt-2 font-normal">
            {description}
          </div>
        </div>
        <div className="hidden md:flex md:absolute right-0 bottom-5 lg:relative lg:flex-col lg:pl-4 xl:h-full lg:justify-center">
          <button
            id="svgButton"
            type="button"
            className="p-2 cursor-pointer hover-bg-gray-800 rounded-md"
            onClick={() => toggleIcons('icon2')}
          >
            <div id="svgContainer">
              {iconVisibility.icon1 && (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.8619 16.0091C11.7477 16.084 11.6379 16.1546 11.5334 16.2206C11.1821 16.4425 10.8884 16.6146 10.6809 16.732M10.3453 16.9158C10.3537 16.9114 10.4399 16.8641 10.5231 16.8184C10.5965 16.7782 10.6675 16.7392 10.6809 16.732M10.3453 16.9158C10.13 17.03 9.86231 17.026 9.6476 16.9121L9.3 16.732C9.3 16.732 9.28016 16.7201 9.24561 16.6992C9.2327 16.6914 9.21774 16.6824 9.201 16.6723C9.04154 16.5759 8.72006 16.3807 8.46663 16.2206C7.76556 15.7777 6.82731 15.1314 5.88539 14.3197C4.04447 12.7332 2 10.3523 2 7.5C2 5.01472 4.01472 3 6.5 3C7.37226 3 8.18618 3.24811 8.87539 3.67736C9.30375 3.94415 9.68394 4.28092 10 4.67158C10.3161 4.28092 10.6962 3.94415 11.1246 3.67736C11.8138 3.24811 12.6277 3 13.5 3C15.9853 3 18 5.01472 18 7.5C18 10.3523 15.9555 12.7332 14.1146 14.3197C13.3553 14.974 12.5983 15.5209 11.9661 15.9404C11.931 15.9637 11.8963 15.9866 11.8619 16.0091M9.25564 16.3228C9.26117 16.2968 9.26689 16.2716 9.27276 16.2473C9.27063 16.2527 9.26858 16.258 9.26662 16.2631C9.26244 16.2811 9.25877 16.301 9.25564 16.3228ZM9.99244 15.3979C10.1782 15.2919 10.432 15.1421 10.7323 14.9524C11.3906 14.5366 12.2648 13.9336 13.1354 13.1834C14.9198 11.6456 16.5 9.6516 16.5 7.5C16.5 5.84315 15.1569 4.5 13.5 4.5C12.5577 4.5 11.7183 4.93252 11.1661 5.61504L10 7.05642L8.83386 5.61504C8.28167 4.93252 7.44226 4.5 6.5 4.5C4.84315 4.5 3.5 5.84315 3.5 7.5C3.5 9.6516 5.08025 11.6456 6.86461 13.1834C7.7352 13.9336 8.60944 14.5366 9.26774 14.9524C9.43285 15.0567 9.63287 15.1795 9.79509 15.2783C9.8743 15.3265 9.94311 15.3682 9.99244 15.3979Z" fill="#D1D5DB"/>
                </svg>
              )}
              {iconVisibility.icon2 && (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M9.65298 16.9149L9.6476 16.9121L9.62912 16.9024C9.61341 16.8941 9.59102 16.8822 9.56238 16.8667C9.50511 16.8358 9.42281 16.7907 9.31906 16.732C9.11164 16.6146 8.81794 16.4425 8.46663 16.2206C7.76556 15.7777 6.82731 15.1314 5.88539 14.3197C4.04447 12.7332 2 10.3523 2 7.5C2 5.01472 4.01472 3 6.5 3C7.9144 3 9.17542 3.65238 10 4.67158C10.8246 3.65238 12.0856 3 13.5 3C15.9853 3 18 5.01472 18 7.5C18 10.3523 15.9555 12.7332 14.1146 14.3197C13.1727 15.1314 12.2344 15.7777 11.5334 16.2206C11.1821 16.4425 10.8884 16.6146 10.6809 16.732C10.5772 16.7907 10.4949 16.8358 10.4376 16.8667C10.409 16.8822 10.3866 16.8941 10.3709 16.9024L10.3524 16.9121L10.347 16.9149L10.3453 16.9158C10.13 17.03 9.87 17.03 9.65529 16.9161L9.65298 16.9149Z" fill="#E5E7EB"/>
                </svg>
              )}
            </div>
          </button>
          <input
            type="text"
            value="https://www.example.com"
            id="linkInput"
            ref={linkInputRef}
            className="hidden"
          />
          <button onClick={copyLink} type="button" className="p-2 cursor-pointer hover-bg-gray-800 rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M12.2322 4.23223C13.2085 3.25592 14.7915 3.25592 15.7678 4.23223C16.7441 5.20854 16.7441 6.79146 15.7678 7.76777L14.5434 8.9921C14.2505 9.28499 14.2505 9.75987 14.5434 10.0528C14.8363 10.3457 15.3112 10.3457 15.6041 10.0528L16.8284 8.82843C18.3905 7.26633 18.3905 4.73367 16.8284 3.17157C15.2663 1.60948 12.7337 1.60948 11.1716 3.17157L8.17157 6.17157C6.60948 7.73367 6.60948 10.2663 8.17157 11.8284C8.24449 11.9013 8.31963 11.9709 8.3968 12.0372C8.71107 12.307 9.18457 12.271 9.45441 11.9567C9.72424 11.6425 9.68822 11.169 9.37395 10.8991C9.32556 10.8576 9.27828 10.8138 9.23223 10.7678C8.25592 9.79145 8.25592 8.20854 9.23223 7.23223L12.2322 4.23223Z" fill="#D1D5DB"/>
              <path d="M11.6032 7.96278C11.2889 7.69295 10.8154 7.72897 10.5456 8.04324C10.2758 8.3575 10.3118 8.83101 10.626 9.10084C10.6744 9.14239 10.7217 9.18618 10.7678 9.23222C11.7441 10.2085 11.7441 11.7914 10.7678 12.7678L7.76777 15.7678C6.79146 16.7441 5.20854 16.7441 4.23223 15.7678C3.25592 14.7914 3.25592 13.2085 4.23223 12.2322L5.45657 11.0079C5.74946 10.715 5.74946 10.2401 5.45657 9.94723C5.16367 9.65433 4.6888 9.65433 4.39591 9.94723L3.17157 11.1716C1.60948 12.7337 1.60948 15.2663 3.17157 16.8284C4.73367 18.3905 7.26633 18.3905 8.82843 16.8284L11.8284 13.8284C13.3905 12.2663 13.3905 9.73366 11.8284 8.17156C11.7555 8.09864 11.6804 8.02904 11.6032 7.96278Z" fill="#D1D5DB"/>
            </svg>
          </button>
        </div>
      </div>
      <div className="mt-10 md:mt-0 relative flex justify-center">
        <div className="block-img w-full h-72 lg:h-full sm:w-96 md:w-64">
          <img className="object-cover w-full h-full lg:h-64 rounded-xl" src={img} alt="" />
        </div>
        <div className="md:hidden absolute bottom-0 mb-4 flex bg-gray-900 rounded-xl p-2">
          <button
            id="svgButton_2"
            type="button"
            className="p-2 cursor-pointer hover-bg-gray-800 rounded-md"
            onClick={() => toggleIcons('icon4')}
          >
            <div id="svgContainer_2">
              {iconVisibility.icon3 && (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.8619 16.0091C11.7477 16.084 11.6379 16.1546 11.5334 16.2206C11.1821 16.4425 10.8884 16.6146 10.6809 16.732M10.3453 16.9158C10.3537 16.9114 10.4399 16.8641 10.5231 16.8184C10.5965 16.7782 10.6675 16.7392 10.6809 16.732M10.3453 16.9158C10.13 17.03 9.86231 17.026 9.6476 16.9121L9.3 16.732C9.3 16.732 9.28016 16.7201 9.24561 16.6992C9.2327 16.6914 9.21774 16.6824 9.201 16.6723C9.04154 16.5759 8.72006 16.3807 8.46663 16.2206C7.76556 15.7777 6.82731 15.1314 5.88539 14.3197C4.04447 12.7332 2 10.3523 2 7.5C2 5.01472 4.01472 3 6.5 3C7.37226 3 8.18618 3.24811 8.87539 3.67736C9.30375 3.94415 9.68394 4.28092 10 4.67158C10.3161 4.28092 10.6962 3.94415 11.1246 3.67736C11.8138 3.24811 12.6277 3 13.5 3C15.9853 3 18 5.01472 18 7.5C18 10.3523 15.9555 12.7332 14.1146 14.3197C13.3553 14.974 12.5983 15.5209 11.9661 15.9404C11.931 15.9637 11.8963 15.9866 11.8619 16.0091M9.25564 16.3228C9.26117 16.2968 9.26689 16.2716 9.27276 16.2473C9.27063 16.2527 9.26858 16.258 9.26662 16.2631C9.26244 16.2811 9.25877 16.301 9.25564 16.3228ZM9.99244 15.3979C10.1782 15.2919 10.432 15.1421 10.7323 14.9524C11.3906 14.5366 12.2648 13.9336 13.1354 13.1834C14.9198 11.6456 16.5 9.6516 16.5 7.5C16.5 5.84315 15.1569 4.5 13.5 4.5C12.5577 4.5 11.7183 4.93252 11.1661 5.61504L10 7.05642L8.83386 5.61504C8.28167 4.93252 7.44226 4.5 6.5 4.5C4.84315 4.5 3.5 5.84315 3.5 7.5C3.5 9.6516 5.08025 11.6456 6.86461 13.1834C7.7352 13.9336 8.60944 14.5366 9.26774 14.9524C9.43285 15.0567 9.63287 15.1795 9.79509 15.2783C9.8743 15.3265 9.94311 15.3682 9.99244 15.3979Z" fill="#D1D5DB"/>
                </svg>
              )}
              {iconVisibility.icon4 && (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M9.65298 16.9149L9.6476 16.9121L9.62912 16.9024C9.61341 16.8941 9.59102 16.8822 9.56238 16.8667C9.50511 16.8358 9.42281 16.7907 9.31906 16.732C9.11164 16.6146 8.81794 16.4425 8.46663 16.2206C7.76556 15.7777 6.82731 15.1314 5.88539 14.3197C4.04447 12.7332 2 10.3523 2 7.5C2 5.01472 4.01472 3 6.5 3C7.9144 3 9.17542 3.65238 10 4.67158C10.8246 3.65238 12.0856 3 13.5 3C15.9853 3 18 5.01472 18 7.5C18 10.3523 15.9555 12.7332 14.1146 14.3197C13.1727 15.1314 12.2344 15.7777 11.5334 16.2206C11.1821 16.4425 10.8884 16.6146 10.6809 16.732C10.5772 16.7907 10.4949 16.8358 10.4376 16.8667C10.409 16.8822 10.3866 16.8941 10.3709 16.9024L10.3524 16.9121L10.347 16.9149L10.3453 16.9158C10.13 17.03 9.87 17.03 9.65529 16.9161L9.65298 16.9149Z" fill="#E5E7EB"/>
                </svg>
              )}
            </div>
          </button>
          <input
            type="text"
            value="https://www.example.com"
            id="linkInput"
            ref={linkInputRef}
            className="hidden"
          />
          <button onClick={copyLink} type="button" className="p-2 cursor-pointer hover-bg-gray-800 rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M12.2322 4.23223C13.2085 3.25592 14.7915 3.25592 15.7678 4.23223C16.7441 5.20854 16.7441 6.79146 15.7678 7.76777L14.5434 8.9921C14.2505 9.28499 14.2505 9.75987 14.5434 10.0528C14.8363 10.3457 15.3112 10.3457 15.6041 10.0528L16.8284 8.82843C18.3905 7.26633 18.3905 4.73367 16.8284 3.17157C15.2663 1.60948 12.7337 1.60948 11.1716 3.17157L8.17157 6.17157C6.60948 7.73367 6.60948 10.2663 8.17157 11.8284C8.24449 11.9013 8.31963 11.9709 8.3968 12.0372C8.71107 12.307 9.18457 12.271 9.45441 11.9567C9.72424 11.6425 9.68822 11.169 9.37395 10.8991C9.32556 10.8576 9.27828 10.8138 9.23223 10.7678C8.25592 9.79145 8.25592 8.20854 9.23223 7.23223L12.2322 4.23223Z" fill="#D1D5DB"/>
              <path d="M11.6032 7.96278C11.2889 7.69295 10.8154 7.72897 10.5456 8.04324C10.2758 8.3575 10.3118 8.83101 10.626 9.10084C10.6744 9.14239 10.7217 9.18618 10.7678 9.23222C11.7441 10.2085 11.7441 11.7914 10.7678 12.7678L7.76777 15.7678C6.79146 16.7441 5.20854 16.7441 4.23223 15.7678C3.25592 14.7914 3.25592 13.2085 4.23223 12.2322L5.45657 11.0079C5.74946 10.715 5.74946 10.2401 5.45657 9.94723C5.16367 9.65433 4.6888 9.65433 4.39591 9.94723L3.17157 11.1716C1.60948 12.7337 1.60948 15.2663 3.17157 16.8284C4.73367 18.3905 7.26633 18.3905 8.82843 16.8284L11.8284 13.8284C13.3905 12.2663 13.3905 9.73366 11.8284 8.17156C11.7555 8.09864 11.6804 8.02904 11.6032 7.96278Z" fill="#D1D5DB"/>
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderCollection;
