import React, { useState } from 'react';
import Logo from '../img/Logo (1).svg';
import Input from '../new-component/input';
import Button from '../new-component/button';
import {ErrorMessage, Field, Form, Formik} from "formik";

interface FormValues {
  email: string;
}

function PasswordResetForm() {
  const [emailInput, setEmailInput] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);


  const requestPasswordRecovery = async (values: FormValues) => {
    fetch('/api/user/v1/auth/passwords', {
    // fetch('http://localhost:8080/v1/auth/passwords', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
        .then(response => {
          console.log('Received response ', response)
          setIsEmailSent(true);
        })
        .catch(e => {
          console.error('Received error ', e);
          setIsEmailSent(false);
        });
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.email) errors.email = 'Email is required';

    return errors;
  };

  const onSubmit = (values: FormValues) => {
    requestPasswordRecovery(values);
  };

  // const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmailInput(event.target.value);
  // };
  //
  // const handleSubmitButtonClick = () => {
  //   setIsEmailSent(true);
  // };

  return (

    <div className="flex min-h-full flex-col justify-center px-4 pt-32 pb-20 md:pt-56 md:pb-64 lg:px-8 bg-gray-950">

      {isEmailSent ? (

        <div id="email-sent" className="sm:mx-auto sm:w-full sm:max-w-xs flex flex-col items-center text-center">
          <div className="flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <path d="M18 25.5L22.5 30L30 19.5M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24Z" stroke="#6EE7B7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className="text-white text-2xl font-semibold mt-8">
            Email Sent
          </div>
          <div className="text-gray-400 text-base font-normal mt-1">
            Check your email and open the link we sent to continue resetting your password.
          </div>
        </div>

      ) : (

        <div>

          <div className="sm:mx-auto sm:w-full sm:max-w-xs">
            <img className="mx-auto h-8 w-auto" src={Logo} alt="Logo" />
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xs">

            <div className="mt-8 text-gray-400 text-base font-normal text-center">
              To reset your password, enter your email and we’ll send you a recovery link.
            </div>

            <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: ''
                }}
                validate={validate}
                onSubmit={onSubmit}>

              {({ errors, touched, isValid, handleChange, handleBlur, values }) => (

                <div className="space-y-4">
                  <div>
                    <Form>
                      <div className="flex items-center justify-between">
                        <label className="block text-sm font-medium leading-5 text-gray-400">
                          Email
                        </label>
                      </div>
                      <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                        <Field
                            type="email"
                            id="email"
                            name="email"
                            placeholder=""
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`outline-none w-full text-gray-500 text-base font-normal ${errors.email && touched.email ? 'border-red-500' : 'border-gray-300'}`}
                            style={{ background: 'none' }} />
                        <ErrorMessage name="email" component="div" className="text-xs text-red-500" />
                      </div>
                    </Form>
                  </div>
                  {/*<div>
                    <Form>
                      <button
                          type="submit"
                          disabled={!isValid}
                          className={`px-4 py-2 text-white bg-blue-500 rounded-md ${!isValid ? 'opacity-50' : ''}`}
                      >
                        Send Link to Email
                      </button>
                    </Form>
                  </div>*/}

                  <div className="space-y-6 pt-4">
                    <Button
                        variant='primary'
                        type='submit'
                        variantType='button'
                        variantSize='full'
                        isDisabled={!isValid}>
                      Send Link to Email
                    </Button>
                  </div>

                </div>

              )}

            </Formik>

            {/*<form className="space-y-9 mt-4" onSubmit={handleSubmitButtonClick}>
              <Input label='Email' id="email" name="email" type="email" value={emailInput} onChange={handleEmailInputChange} required />

              <Button variant="primary" type="submit" variantType="button" variantSize="full" id="button-submit">
                Send Link to Email
              </Button>
            </form>
            <div className="pt-4">
              <Button variant='secondary' variantSize='full' variantType='link' to='/sign-up'>
                Create Account
              </Button>
            </div>*/}

          </div>

        </div>

      )}

    </div>
  );
}

export default PasswordResetForm;
