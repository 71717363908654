import React from 'react';
import Button from './button';
import { WalletProfile } from "./WalletProfile";

interface ModalProps {
  id?: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  img: string;
  text: string;
  data: { img: string; text: string }[];
  onClick?: () => void;
}

const Modal: React.FC<ModalProps> = ({id, title, isOpen, onClose, children, img, text, data, onClick }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed px-4 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-950 bg-opacity-50 z-50">
      <div id={id ? id : 'noid'} className="absolute bg-gray-900 p-4 rounded-xl w-full md:w-2/3 lg:w-2/4 xl:w-2/5 2xl:w-2/6">
        <div className="flex items-center justify-between mb-6">
          <div className="text-2xl text-gray-50 font-medium">
            {title}
          </div>
          <button onClick={onClose} className="top-2 right-2 text-gray-500 pr-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z" fill="#D1D5DB"/>
            </svg>
          </button>
        </div>

        {/*<div className="grid gap-4">*/}
          {/*{data.map((item, index) => (
            <div key={index} className="flex justify-between bg-gray-800 px-5 py-3 rounded-xl">
              <div className="flex gap-3 items-center">
                <img src={item.img} alt="" />
                <div className="text-lg font-normal text-gray-50">{item.text}</div>
              </div>
              <Button variant='transparent' variantType='button' variantSize='block' onClick={onClick}>
                Connect
              </Button>
            </div>
          ))}*/}
          <WalletProfile modalClose={onClose} />
        {/*</div>*/}

        {children}
      </div>
    </div>
  );
};

export default Modal;

