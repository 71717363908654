import React, { ReactNode } from 'react';
import Profile from "../img/profile.svg";

interface HeaderProfileProps {
  userName: string;
  userAddress: string;
  children?: ReactNode;
}

const HeaderProfile: React.FC<HeaderProfileProps> = ({ userName, userAddress, children }) => {
  return (
    <div className="pb-8 md:pb-12 xl:pb-20">
      <div className="relative md:flex md:items-center md:justify-between">
        <div className="flex flex-col items-center md:flex-row md:gap-8">
          <img src={Profile} alt="" />
          <div className="mt-9 flex flex-col items-center md:mt-0 md:items-start">
            <div className="text-3xl font-bold text-white">
              {userName}
            </div>
            <div className="flex mt-3 items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.81266 10.3979C5.77477 10.4493 5.794 10.5225 5.85211 10.549C8.03846 11.5462 10.1616 13.1994 11.9315 14.8846C11.9703 14.9215 12.031 14.9215 12.0698 14.8846C13.8396 13.1994 15.9627 11.5462 18.1491 10.549C18.2072 10.5225 18.2264 10.4493 18.1885 10.3979L12.4031 2.54625C12.2033 2.27505 11.7979 2.27505 11.5981 2.54625L5.81266 10.3979ZM18.5071 12.2402C18.5631 12.1561 18.4746 12.0534 18.3841 12.0983C16.4544 13.0565 14.5297 14.5849 12.8791 16.1872C12.39 16.6621 11.6113 16.6621 11.1222 16.1873C9.47149 14.585 7.54683 13.0565 5.61705 12.0983C5.52661 12.0534 5.43805 12.1561 5.49406 12.2402L11.5846 21.3759C11.7825 21.6728 12.2187 21.6728 12.4166 21.3759L18.5071 12.2402Z" fill="#9CA3AF" />
              </svg>
              <div className="font-medium text-gray-300 text-xl">
                {userAddress}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute right-0 top-0 md:contents">
          {children}
        </div>
      </div>
    </div>
  );
};

export default HeaderProfile;
