import React from "react";
import Button from "./button";
import Input from '../new-component/input';
import { ReactComponent as EyeIcon } from '../img/eye.svg';


const ChangePassword: React.FC = () => {

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <div className="bg-gray-800 bg-opacity-50 px-4 pt-8 pb-12 md:py-12 md:px-10 xl:px-24 w-full rounded-2xl">
      <div className="text-center text-white font-semibold text-xl md:text-2xl">
        Change Password
      </div>
      <div className="text-center text-gray-400 font-normal text-base mt-2">
        Choose an unique and strong password to keep the account safe.
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-xs pt-8">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <Input label='Current Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>
          <Input label='New Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>
          <Input label='Retype New Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>
          <div className="flex w-full justify-center pt-10">
            <Button variant="secondary" variantType='button' type="submit" variantSize='block'>
              Change Password
            </Button>
          </div>
        </form>
      </div>
      <div className="text-sm text-center font-normal text-gray-400 pt-6">
        Changing your password will sign you out from other devices.
      </div>
    </div>
  );
};

export default ChangePassword;
