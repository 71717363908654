import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
// import { configureChains, createClient, WagmiConfig } from "wagmi";
// import { mainnet } from "wagmi/chains";

import { Home } from "./pages/home";
import { SignIn } from "./pages/SignIn";
import { CollectionPage } from "./pages/CollectionPage";
import { CreateAccount } from "./pages/CreateAccount";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ForgotPasswordLink } from "./pages/ForgotPasswordLink";
import { ItemPage } from "./pages/ItemPage";
import Setting from "./pages/Setting";
import { ChangeEmail } from "./pages/ChangeEmail";
import Profile from "./pages/Profile";
import { MarketplaceHome } from "./components/marketplaceHome";
import { ActivateAccount } from "./pages/ActivateAccount";
import { WagmiConfig, createClient, configureChains, mainnet } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
// import { LedgerConnector } from '@ledgerhq/ledger-wagmi-connector';
// import { PhantomConnector } from 'phantom-wagmi-connector'

import theme from "./theme";
import "./style-component/style.css";
import 'tailwindcss/base.css';
import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css';

import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

const projectId = "5f53b0299462693daec37a2c802d477a";
const alchemyApiKey = "J2BlJ6OyUUHhlUulpy7urfQKg6_DHbOp"; // Free tier

const { chains, provider, webSocketProvider } = configureChains(
    [mainnet],
    [alchemyProvider({ apiKey: alchemyApiKey }), publicProvider()],
)

// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: 'b87f52445c427f69d72b948574999fe7',
      },
    }),
    /*new LedgerConnector({
      chains,
      options: {
        enableDebugLogs: false,
        // passed to WalletConnect
        chainId: 1,
        // specify if no rpc, passed to WalletConnect
        infuraId: 'YOUR_INFURA_ID',
        // specify chain:URL if no infuraId, passed to WalletConnect
        rpc: {
          1: 'https://cloudflare-eth.com/', // Mainnet
          137: 'https://polygon-rpc.com/',  // Polygon
        }
      },
    }),
    new PhantomConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),*/
  ],
  provider,
  webSocketProvider,
})

const ethereumClient = new EthereumClient(client, chains);

const App: React.FC = () => {
  return (
    <WagmiConfig client={client}>
      <Router>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route path="/" element={<MarketplaceHome />} />
            <Route path="/home" element={<Home />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<CreateAccount />} />
            <Route path="/accounts/:userId/:tokenId" element={<ActivateAccount />} />
            <Route path="/passwords" element={<ForgotPassword />} />
            <Route path="/passwords/:tokenId" element={<ForgotPasswordLink />} />
            {/*<Route path="/createaccount" element={<CreateAccount />} />*/}
            {/*<Route path="/forgotpasswordlink" element={<ForgotPasswordLink />} />*/}
            <Route path="/collections/:collectionAddress" element={<CollectionPage />} />
            <Route path="/collections/:collectionAddress/:tokenId" element={<ItemPage />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/changeEmail" element={<ChangeEmail />} />
            <Route path="/settings/*" element={<Setting />} />
          </Routes>
        </ChakraProvider>
      </Router>
    </WagmiConfig>
  );
};

export default App;
