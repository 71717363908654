import React, { useState } from 'react';
import Button from "../new-component/button";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface ModalMakeOfferProps {
  titleModal: string;
  isOpen: boolean;
  onClose: () => void;
  img: string;
  badgeText: string;
  itemName: string;
  statusPrice: string;
  price: string;
}

const ModalMakeOffer: React.FC<ModalMakeOfferProps> = ({ 
  isOpen, 
  onClose, 
  titleModal,
  img,
  badgeText,
  itemName,
  statusPrice,
  price,
 }) => {
  const [isButtonHidden, setIsButtonHidden] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);
  
  if (!isOpen) return null;

  const handleButtonClick = () => {
    setIsButtonHidden(true);
  };

  return (
    <div className="z-50 fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-950 bg-opacity-50 px-4">
      <div className="relative rounded-xl p-4 md:p-6 bg-gray-900 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-between items-center">
          <div className="text-2xl text-gray-50 font-medium">{titleModal}</div>
          <button onClick={onClose} className="top-2 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z" fill="#D1D5DB" />
            </svg>
          </button>
        </div>
        <div className="flex gap-4 items-center pt-6">
          <img className="w-[72px] h-[72px] rounded-md" src={img} alt="" />
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col items-start gap-1">
              <div className="text-violet-400 text-sm font-normal">{badgeText}</div>
              <div className="text-white text-base md:text-lg font-medium">{itemName}</div>
            </div>
            <div className="flex flex-col items-end gap-1">
              <div className="text-gray-400 text-sm font-normal">{statusPrice}</div>
              <div className="font-medium text-base md:text-lg text-gray-300">{price}</div>
            </div>
          </div>
        </div>
        <form action="" className="py-6" id="inputContainer">
          <div className="flex">
            <div className="w-full">
              <label htmlFor="number" className="block text-sm text-left font-medium text-gray-400">Price</label>
              <div className="flex mt-2 w-full gap-4 items-center">
                <div className="flex w-full">
                  <input id="number" type="number" required className="outline-none block w-full rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2 text-white text-base font-normal" />
                </div>
                <div className="text-gray-400 font-medium text-lg">ETH</div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <label htmlFor="duration" className="block text-left text-sm font-medium text-gray-400">
              Duration
            </label>
            <div className="grid grid-cols-3 mt-2 items-center">
              <select
                required
                className="mr-3 outline-none rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2 text-white text-lg font-normal"
                id="duration"
                name="duration" 
              >
                <option value="">Days</option>
                <option>1 day</option>
                <option>2 days</option>
                <option>3 days</option>
              </select>
              <div className="col-span-2 w-full">
                <div className="react-datepicker-wrapper">
                <DatePicker
                    selected={selectedDateTime}
                    onChange={(date) => setSelectedDateTime(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Aug 28, 2023  5:05 PM"
                    required
                    className="outline-none w-48 md:w-64 flex rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-2 px-2 text-white text-sm font-normal"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        {!isButtonHidden && (
          <Button
            id="make-offer"
            variant='primary'
            variantType='button'
            variantSize='full'
            type='submit'
            onClick={handleButtonClick}
          >
            Make Offer
          </Button>
        )}
      </div>
    </div>
  );
};

export default ModalMakeOffer;
