import React, { ReactNode } from 'react';

interface TabelXLRowProps {
  img: string
  itemName: string;
  collectionName: string;
  price: string;
  usdPrice: string;
  expiration: string;
  created: string;
}

interface TabelXLProps {
  children?: ReactNode;
  tableData: TabelXLRowProps[];
}


const TabelXL: React.FC<TabelXLProps> = ({ tableData, children }) => {
  return (
    <div className="overflow-x-scroll no-scrollbar">
      <div className="pb-16 lg:pb-24" style={{ width: '1170px' }}>
      <div className="grid grid-cols-7 whitespace-nowrap overflow-x-scroll no-scrollbar rounded-t-xl border border-gray-800">
        <div className="text-gray-400 text-base font-normal text-start px-8 py-2">Offer</div>
        <div className="text-gray-400 text-base font-normal text-start px-8 py-2">Collection</div>
        <div className="text-gray-400 text-base font-normal text-end px-8 py-2">Price</div>
        <div className="text-gray-400 text-base font-normal text-center px-8 py-2">USD Price</div>
        <div className="text-gray-400 text-base font-normal text-center px-8 py-2">Expiration</div>
        <div className="text-gray-400 text-base font-normal text-start px-8 py-2">Created</div>
      </div>
      <div className="border-x border-b border-gray-800 rounded-b-xl overflow-x-scroll no-scrollbar whitespace-nowrap" style={{ width: '1170px', maxHeight: '424px' }}>
        {tableData.map((rowData, index) => (
          <div className="grid grid-cols-7 border-t border-gray-800 whitespace-nowrap" key={index}>
            <div className="px-6 py-3 flex items-center gap-3">
              <img className="h-10 w-10 rounded" src={rowData.img} alt="" />
              <div className="text-base font-semibold text-white">
                {rowData.itemName}
              </div>
            </div>
            <div className="text-base font-normal text-white px-8 py-2 text-center flex items-center">{rowData.collectionName}</div>
            <div className="text-base font-normal text-green-400 px-8 py-2 justify-end flex items-center">{rowData.price}</div>
            <div className="text-base font-normal text-white px-8 py-2 justify-center flex items-center">{rowData.usdPrice}</div>
            <div className="text-base font-normal text-white px-8 py-2 justify-center flex items-center">{rowData.expiration}</div>
            <div className="text-base font-normal text-white px-8 py-2 flex items-center">{rowData.created}</div>
            <div className="flex items-center justify-center pr-8">
              {children}
            </div>
          </div>  
        ))}
      </div>
      </div>
    </div>
  );
};

export default TabelXL;
