import React from "react";
import Backgrounds from "../components/backgrounds";
import ChangeEmailForms from "../new-component/changeEmailForms";
import { Footer } from "../new-component/footer";

export const ChangeEmail: React.FC = () => {
  return (
    <Backgrounds>
        <ChangeEmailForms/>
      <Footer/>
    </Backgrounds>
  );
};
