import React from "react";
import Backgrounds from "../components/backgrounds";
import { Footer } from "../new-component/footer";
import SignUpForms from "../new-component/signInForms";

export const SignIn: React.FC = () => {
  return (
    <Backgrounds>
       <SignUpForms/>
      <Footer />
    </Backgrounds>
  );
};
