export const styles = {
  global: {
    "div [aria-hidden='true']": {
      border: "none",
    },
    dl: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    "#collections-carousel-container .owl-carousel .owl-stage-outer": {
      overflow: "unset",
      WebkitTransform: "translate3d(-38.5%,0,0)",
    },
    "#collections-carousel-container .owl-carousel .owl-item": {
      width: "186px",
      marginRight: "10px",
    },
    "#collections-carousel-container .owl-carousel .item": {
      height: "326px",
      padding: "0",
      color: "white",
      fontFamily: "Open Sans Semibold",
      fontSize: "30px",
    },
    ".carousel-nav-container button:hover path": {
      fill: "white",
      stroke: "white"
    },
    ".tab-active": {
      backgroundColor: "rgb(17 24 39 / 1)",
      borderRadius: "0.75rem"
    },
    "w3m-modal-backcard": {
      display: "none !important"
    }
  },
}
