import React from "react";
import Backgrounds from "../components/backgrounds";
import { Footer } from "../new-component/footer";
import ActivateAccountForms from "../new-component/activateAccountForms";


export const ActivateAccount: React.FC = () => {
  return (
    <Backgrounds>
        <ActivateAccountForms/>
      <Footer/>
    </Backgrounds>
  );
};
