import React, { useState } from 'react';
import Logo from '../img/Logo (1).svg';
import Input from '../new-component/input';
import Button from '../new-component/button';
import { ReactComponent as EyeIcon } from '../img/eye.svg';
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";

interface FormValues {
  newPassword: string;
  confirmNewPassword: string;
}

function PasswordResetLinkForm() {
  const {tokenId} = useParams();
  const [emailInput, setEmailInput] = useState('');
  const [isPasswordChanges, setIsPasswordChanges] = useState(false);

  const requestPasswordReset = async (values: FormValues) => {
    fetch(`/api/user/v1/auth/tokens/${tokenId}`, {
    // fetch(`http://localhost:8080/v1/auth/tokens/${tokenId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
        .then(response => {
          console.log('Received response ', response)
          setIsPasswordChanges(true);
        })
        .catch(e => {
          console.error('Received error ', e);
          setIsPasswordChanges(false);
        });
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.newPassword) errors.newPassword = 'Password is required';
    if (!values.confirmNewPassword) errors.confirmNewPassword = 'Please re-type the already entered password';

    return errors;
  };

  const onSubmit = (values: FormValues) => {
    requestPasswordReset(values);
  };

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
  };

  const handleSubmitButtonClick = () => {
    setIsPasswordChanges(true);
  };


  return (
    <div className="flex min-h-full flex-col justify-center px-4 pt-32 pb-20 md:pt-56 md:pb-64 lg:px-8 bg-gray-950">
      {isPasswordChanges ? (
        <div id="email-sent" className="sm:mx-auto sm:w-full sm:max-w-xs flex flex-col items-center text-center">
          <div className="flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <path d="M18 25.5L22.5 30L30 19.5M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24Z" stroke="#6EE7B7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className="text-white text-2xl font-semibold mt-8">
          You have successfully changed your password.
          </div>
          <div className="text-gray-400 text-base font-normal mt-1">
            You can now sign in with your email and new password.
          </div>
          <div className="space-y-4 flex flex-col w-full mt-10">
            <Button variant='secondary' type='submit' variantType='link' variantSize='full' to="/sign-in">
              Sign In
            </Button>
            <Button variant='secondary' variantSize='full' variantType='link' to='/sign-up'>
              Create Account
            </Button>
          </div>
        </div>
      ) : (
        <div id="email-block" className="sm:mx-auto sm:w-full sm:max-w-xs">
          <div className="">
            <img className="mx-auto h-8 w-auto" src={Logo} alt="Your Company" />
          </div>

          <div className="mt-8 text-gray-400 text-base font-normal text-center">
            Choose an unique and strong password to keep the account safe.
          </div>

          <Formik
              initialValues={{
                newPassword: '',
                confirmNewPassword: ''
              }}
              validate={validate}
              onSubmit={onSubmit}
          >
            {({ errors, touched, isValid, handleChange, handleBlur, values }) => (
                <div className="flex justify-between items-end min-h-full">

                  <div>
                    <Form>
                      <label htmlFor="newPassword" className="block text-sm font-medium text-gray-600">
                        New Password
                      </label>
                      <Field
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          placeholder=""
                          value={values.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`w-full px-3 py-2 border rounded-md ${errors.newPassword && touched.newPassword ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-blue-500`}
                      />
                      <ErrorMessage name="newPassword" component="div" className="text-xs text-red-500" />
                    </Form>
                  </div>
                  <div>
                    <Form>
                      <label htmlFor="confirmNewPassword" className="block text-sm font-medium text-gray-600">
                        Retype New Password
                      </label>
                      <Field
                          type="password"
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                          placeholder=""
                          value={values.confirmNewPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`w-full px-3 py-2 border rounded-md ${errors.confirmNewPassword && touched.confirmNewPassword ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-blue-500`}
                      />
                      <ErrorMessage name="confirmNewPassword" component="div" className="text-xs text-red-500" />
                    </Form>
                  </div>
                  <div>
                    <Form>
                      <button
                          type="submit"
                          disabled={!isValid}
                          className={`px-4 py-2 text-white bg-blue-500 rounded-md ${!isValid ? 'opacity-50' : ''}`}
                      >
                        Reset Password
                      </button>
                    </Form>
                  </div>
                </div>
            )}
          </Formik>


          <form className="space-y-4 mt-4" onSubmit={handleSubmitButtonClick}>
            <Input label='New Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>
            <Input label='Retype New Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>
            <div className="pt-6">
              <Button variant='primary' type='submit' variantType='button' variantSize='full' id="button-submit">
                Reset Password
              </Button>
            </div>
          </form>
          <div className="pt-4">
            <Button variant='secondary' variantSize='full' variantType='link' to='/sign-up'>
              Create Account
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PasswordResetLinkForm;
