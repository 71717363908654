import React, { useState } from 'react';
import Logo from '../img/Logo (1).svg';
import Input from '../new-component/input';
import Button from '../new-component/button';
import { ReactComponent as EyeIcon } from '../img/eye.svg';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useParams} from "react-router-dom";

interface FormValues {
  password: string;
  confirmPassword: string;
  country: string;
  phone: string;
}

const ActivateAccountForms: React.FC = () => {
  const {userId, tokenId} = useParams();
  const [showPassword, setShowPassword] = useState(false);

  const activateUser = async (values: FormValues) => {
    fetch(`/api/user/v1/auth/users/${userId}/tokens/${tokenId}`, {
    // fetch(`http://localhost:8080/v1/auth/users/${userId}/tokens/${tokenId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.country) errors.country = 'Country is required';
    if (!values.phone) errors.phone = 'Phone is required';
    if (!values.password) errors.password = 'Password is required';
    if (!values.confirmPassword || values.password !== values.confirmPassword) errors.confirmPassword = 'Please confirm the password you\'ve entered';

    return errors;
  };
  // const togglePasswordVisibility = () => {
  //   setShowPassword((prevShowPassword) => !prevShowPassword);
  // };

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  // };

  const onSubmit = (values: FormValues) => {
    activateUser(values);
  };

  return (
      <div className="flex min-h-full flex-col justify-center px-4 pt-32 pb-20 md:pt-56 md:pb-64 lg:px-8 bg-gray-950">

        <div className="sm:mx-auto sm:w-full sm:max-w-xs">
          <img className="mx-auto h-8 w-auto" src={Logo} alt="Logo" />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xs">

        <Formik
            initialValues={{
              country: '',
              phone: '',
              password: '',
              confirmPassword: ''
            }}
            validate={validate}
            onSubmit={onSubmit}>
          {({ errors, touched, isValid, handleChange, handleBlur, values }) => (

              <div className="space-y-4">
                <div>
                  <Form>
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium leading-5 text-gray-400">
                        Country
                      </label>
                    </div>
                    <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                      <Field
                          type="text"
                          id="country"
                          name="country"
                          placeholder=""
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`outline-none w-full text-gray-500 text-base font-normal ${errors.country && touched.country ? 'border-red-500' : 'border-gray-300'}`}
                          style={{ background: 'none' }} />
                      <ErrorMessage name="country" component="div" className="text-xs text-red-500" />
                    </div>
                  </Form>
                </div>
                <div>
                  <Form>
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium leading-5 text-gray-400">
                        Phone
                      </label>
                    </div>
                    <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                      <Field
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder=""
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`outline-none w-full text-gray-500 text-base font-normal ${errors.phone && touched.phone ? 'border-red-500' : 'border-gray-300'}`}
                          style={{ background: 'none' }} />
                      <ErrorMessage name="phone" component="div" className="text-xs text-red-500" />
                    </div>
                  </Form>
                </div>
                <div>
                  <Form>
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium leading-5 text-gray-400">
                        Password
                      </label>
                    </div>
                    <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                      <Field
                          type="password"
                          id="password"
                          name="password"
                          placeholder=""
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`outline-none w-full text-gray-500 text-base font-normal ${errors.password && touched.password ? 'border-red-500' : 'border-gray-300'}`}
                          style={{ background: 'none' }} />
                      <ErrorMessage name="password" component="div" className="text-xs text-red-500" />
                    </div>
                  </Form>
                </div>
                <div>
                  <Form>
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium leading-5 text-gray-400">
                        Confirm password
                      </label>
                    </div>
                    <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                      <Field
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder=""
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`outline-none w-full text-gray-500 text-base font-normal ${errors.confirmPassword && touched.confirmPassword ? 'border-red-500' : 'border-gray-300'}`}
                          style={{ background: 'none' }} />
                      <ErrorMessage name="confirmPassword" component="div" className="text-xs text-red-500" />
                    </div>
                  </Form>
                </div>
                {/*<div>
                  <Form>
                    <button
                        type="submit"
                        disabled={!isValid}
                        className={`px-4 py-2 text-white bg-blue-500 rounded-md ${!isValid ? 'opacity-50' : ''}`}>
                      Activate account
                    </button>
                  </Form>
                </div>*/}

                  <div className="space-y-6 pt-4">
                      <Form>
                          <Button
                              variant='primary'
                              type='submit'
                              variantType='button'
                              variantSize='full'
                              isDisabled={!isValid}>
                              Activate account
                          </Button>
                      </Form>
                  </div>

              </div>
          )}
        </Formik>

        {/*<form className="space-y-4" onSubmit={handleSubmit}>

          <div className="flex gap-4 items-center">
            <Input label='Name' id="fname" name="fname" type="text" required/>
            <Input label='Surname' id="lname" name="lname" type="text" required/>
          </div>

          <Input label='Email' id="email" name="email" type="email" required/>
          <Input label='Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>

          <div className="flex justify-center">
            <Button variant='link' variantType='link' to='/ForgotPassword'>
              Forgot Password?
            </Button>
          </div>

          <div className="space-y-6 pt-4">

          <Button variant='primary' type='submit' variantType='button' variantSize='full'>
            Create Account
          </Button>

          <Button variant='secondary' type='submit' variantType='link' variantSize='full' to="/SignIn">
            Sign In
          </Button>

          </div>
        </form>*/}
      </div>
    </div>
  );
};

export default ActivateAccountForms;
