import React from 'react';

const IconEthereum = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.05093 6.6933C4.01318 6.74453 4.03221 6.81747 4.08985 6.84444C5.47327 7.49171 6.80557 8.51176 7.93192 9.55885C7.97053 9.59475 8.03021 9.59475 8.06883 9.55885C9.19513 8.51175 10.5274 7.49171 11.9108 6.84444C11.9685 6.81747 11.9875 6.74453 11.9498 6.6933L8.40287 1.87967C8.20304 1.60847 7.79765 1.60847 7.59781 1.87967L4.05093 6.6933ZM11.9752 8.7044C12.0327 8.61824 11.9392 8.51561 11.8494 8.56724C10.8146 9.16233 9.78987 9.98552 8.87611 10.8593C8.38659 11.3273 7.6142 11.3273 7.12466 10.8593C6.21085 9.98553 5.18612 9.16233 4.15129 8.56724C4.06152 8.51561 3.96803 8.61824 4.02547 8.7044L7.58432 14.0427C7.78223 14.3395 8.21846 14.3395 8.41637 14.0427L11.9752 8.7044Z" fill="#D1D5DB"/>
        </svg>
    )
}

export default IconEthereum;
