import Button from '../new-component/button';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Item, useGetItems } from "../hooks/useGetItems";
import ItemComponent from "./itemComponent";

interface ItemListProps {
  initialItemsToShow: number;
  itemData: number;
  title?: string;
  buttonText: string;
  showNumConfig: {
    smallScreen: number;
    mediumScreen: number;
    largeScreen: number;
  };
  variantSize: string;
  sort: string
}



const ItemList: React.FC<ItemListProps> = ({ initialItemsToShow, itemData, title, buttonText, showNumConfig, variantSize , sort}) => {

  const getShowNum = () => {
    const { smallScreen, mediumScreen, largeScreen } = showNumConfig;
    if (window.innerWidth >= 1200) {
      return largeScreen;
    } else if (window.innerWidth >= 768) {
      return mediumScreen;
    } else {
      return smallScreen;
    }
  }

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(getShowNum());

  const handleShowMore = () => {
    let newSize = size + getShowNum();
    setSize(newSize);
  };

  const variantSizes: Record<string, string> = {
    deflaut: 'xl:grid-cols-4',
    large: 'xl:grid-cols-6',
  };

  useEffect(() => {
    window.addEventListener('resize', updateItemsToShow);
    return () => {
      window.removeEventListener('resize', updateItemsToShow);
    };
  }, []);

  const totalItems: number = 15;

  const updateItemsToShow = () => {
    setSize(getShowNum());
  };

  const { collectionAddress } = useParams();
  let data: Item[] = useGetItems(page, size, sort, collectionAddress)
  return (
    <div className="pb-16 lg:pb-24">
      <div className="text-white text-2xl md:text-3xl xl:text-4xl font-semibold">
        {title}
      </div>
      <div className="mt-8">
        <ul className={`${variantSizes[variantSize]} grid gap-y-8 gap-x-4 grid-cols-2 md:grid-cols-3 md:gap-x-8`}>
          {
            data.map((item: Item) => (
                <ItemComponent
                    itemName={item.name}
                    ethPrice={item.price}
                    usdPrice={item.usdPrice}
                    href="#"
                    img={item.logo}
                    tokenId={item.tokenId}
                />
            ))
          }
        </ul>
        {totalItems > size && (
          <div className="flex justify-center mt-8">
            <Button variant="secondary" variantType="button" variantSize="block" onClick={handleShowMore}>
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemList;
