import * as React from "react";
import {Link} from "react-router-dom";

interface CollectionProps {
    type: 'text-on' | 'text-under' | 'full-width';
    title: string;
    shortDescription: string;
}

interface PrimaryCollectionProps extends CollectionProps {
    items?: string;
    volume?: string;
    fileUrl?: string;
    permalink?: string;
}

export const Collection: React.FC<PrimaryCollectionProps> = ({
  type,
  title,
  shortDescription,
  items,
  volume,
  fileUrl,
  permalink
}) => {

    if (type === 'text-on') {
        return (
            <Link to={"/collections/"+ permalink}>
                <a href="javascript:void(0)" className="slide w-full active h-[320px] md:h-[360px] relative overflow-hidden">
                    <img className="w-full h-full object-cover rounded-xl" src={fileUrl} alt=""/>
                    <div className="flex justify-between absolute bottom-0 items-end w-full p-6" style={{ background: 'radial-gradient(100% 100% at 50% 0%, rgba(3, 7, 18, 0.00) 54.12%, rgba(3, 7, 18, 0.64) 100%)' }}>
                        <div className="">
                            <div className="text-gray-300 text-sm font-normal font-sans">
                                {items} ∙ {volume}
                            </div>
                            <div className="text-white text-2xl font-semibold font-sans">
                                {title}
                            </div>
                            <div className="text-gray-300 text-lg font-normal font-sans">
                                {shortDescription}
                            </div>
                        </div>
                    </div>
                </a>
            </Link>
        );
    }

    if (type === 'full-width') {
        return (
                title !== 'Coming soon' ?
                <div className="slide active w-full h-[320px] md:h-[336px] xl:h-[400px] rounded-xl relative overflow-hidden">
                    <img className="w-full h-full object-cover" src={fileUrl} alt=""/>
                    <div className="flex justify-between absolute bottom-0 items-end w-full p-6 md:p-8 xl:p-10" style={{ background: 'radial-gradient(100% 100% at 50% 0%, rgba(3, 7, 18, 0.00) 54.12%, rgba(3, 7, 18, 0.64) 100%)' }}>
                        <div className="flex justify-between items-end w-full">
                            <div className="">
                                <div className="text-gray-300 text-sm font-normal">
                                    {items} ∙ {volume}
                                </div>
                                <div className="text-white text-2xl font-semibold">
                                    {title}
                                </div>
                                <div className="text-gray-300 text-lg font-normal">
                                    {shortDescription}
                                </div>
                            </div>
                            <div className="md:block">
                                <Link to={"/collections/"+ permalink}>
                                    <a href="javascript:void(0)" className="text-gray-200 text-base font-medium px-6 py-3 bg-gray-800 rounded-md">
                                        View Collection
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div className="slide active w-full h-[320px] md:h-[336px] xl:h-[400px] rounded-xl relative overflow-hidden">
                        <img className="w-full h-full object-cover" src={fileUrl} alt=""/>
                        <div className="flex justify-between absolute bottom-0 items-end w-full p-6 md:p-8 xl:p-10" style={{ background: 'radial-gradient(100% 100% at 50% 0%, rgba(3, 7, 18, 0.00) 54.12%, rgba(3, 7, 18, 0.64) 100%)' }}>
                            <div className="flex justify-between items-end w-full">
                                <div className="">
                                    <div className="text-gray-300 text-sm font-normal">

                                    </div>
                                    <div className="text-white text-2xl font-semibold">

                                    </div>
                                    <div className="text-gray-300 text-lg font-normal">

                                    </div>
                                </div>
                                <div className="hidden md:block">
                                </div>
                            </div>
                        </div>
                    </div>
        );
    }

    return (
        <Link to={"/collections/"+ permalink}>
            <div className="slide flex-shrink-0 snap-center relative overflow-hidden">
                <a href="javascript:void(0)" className="aspect-square">
                    <img className="h-60 xl:h-64 rounded-xl" src={fileUrl} alt=""/>
                </a>
                <div className="mt-4">
                    <div className="text-gray-100 text-lg font-semibold font-sans">
                        {title}
                    </div>
                    <div className="text-gray-400 text-sm font-normal mt-1 font-sans">
                        {shortDescription}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default Collection;
