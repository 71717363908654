import { Fragment, useState, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import {Link, useNavigate} from "react-router-dom";
import Logo from "../img/Logo (1).svg";
import Notification from "../new-component/notification";
import Button from "../new-component/button";
import Modal from "../new-component/modalConnectWallet";
import ModalWallet from "../new-component/modalWallets";
import WalletComponent from "../new-component/walletComponent";
import Metamask from "../img/Metamask.svg";
import Coinbase from "../img/Coinbase.svg";
import WalletConnect from "../img/WalletConnect.svg";
import Ledger from "../img/Ledger.svg";
import Phantom from "../img/Phantom.svg";
import { useAccount } from "wagmi";
import IconEthereum from "../img/icons/IconEthereum";


const blockData = [
  { img: Metamask, text: 'Metamask' },
  { img: Coinbase, text: 'Coinbase' },
  { img: WalletConnect, text: 'WalletConnect' },
  { img: Ledger, text: 'Ledger' },
  { img: Phantom, text: 'Phantom' },
];

const anonymousUserNavigation = [
    {
        name: 'Sign In',
        href: '/sign-in',
        svg: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15.75 9V5.25C15.75 4.00736 14.7426 3 13.5 3L7.5 3C6.25736 3 5.25 4.00736 5.25 5.25L5.25 18.75C5.25 19.9926 6.25736 21 7.5 21H13.5C14.7426 21 15.75 19.9926 15.75 18.75V15M12 9L9 12M9 12L12 15M9 12L21.75 12" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
  {
    name: 'Create Account',
    href: '/sign-up',
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
        <path d="M12.7508 5C12.7508 7.07107 11.0719 8.75 9.00082 8.75C6.92975 8.75 5.25082 7.07107 5.25082 5C5.25082 2.92893 6.92975 1.25 9.00082 1.25C11.0719 1.25 12.7508 2.92893 12.7508 5Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.50195 19.1182C1.57226 15.0369 4.90269 11.75 9.00082 11.75C13.099 11.75 16.4295 15.0371 16.4997 19.1185C14.2169 20.166 11.6772 20.75 9.00114 20.75C6.32481 20.75 3.78491 20.1659 1.50195 19.1182Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    ),
  },
];

const authenticatedUserNavigation = [
    {
        name: 'Profile',
        href: '/profile',
        svg: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15.75 9V5.25C15.75 4.00736 14.7426 3 13.5 3L7.5 3C6.25736 3 5.25 4.00736 5.25 5.25L5.25 18.75C5.25 19.9926 6.25736 21 7.5 21H13.5C14.7426 21 15.75 19.9926 15.75 18.75V15M12 9L9 12M9 12L12 15M9 12L21.75 12" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    {
        name: 'Settings',
        href: '/settings',
        svg: (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                <path d="M12.7508 5C12.7508 7.07107 11.0719 8.75 9.00082 8.75C6.92975 8.75 5.25082 7.07107 5.25082 5C5.25082 2.92893 6.92975 1.25 9.00082 1.25C11.0719 1.25 12.7508 2.92893 12.7508 5Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.50195 19.1182C1.57226 15.0369 4.90269 11.75 9.00082 11.75C13.099 11.75 16.4295 15.0371 16.4997 19.1185C14.2169 20.166 11.6772 20.75 9.00114 20.75C6.32481 20.75 3.78491 20.1659 1.50195 19.1182Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
    {
        name: 'Log Out',
        href: '/log-out',
        svg: (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                <path d="M12.7508 5C12.7508 7.07107 11.0719 8.75 9.00082 8.75C6.92975 8.75 5.25082 7.07107 5.25082 5C5.25082 2.92893 6.92975 1.25 9.00082 1.25C11.0719 1.25 12.7508 2.92893 12.7508 5Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.50195 19.1182C1.57226 15.0369 4.90269 11.75 9.00082 11.75C13.099 11.75 16.4295 15.0371 16.4997 19.1185C14.2169 20.166 11.6772 20.75 9.00114 20.75C6.32481 20.75 3.78491 20.1659 1.50195 19.1182Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        ),
    },
];

export default function Nav () {
  const { address, connector, isConnected } = useAccount();
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  // @ts-ignore
  const loggedInUser = JSON.parse(window.localStorage.getItem('loggedInUser')) || undefined;
  let userNavigation = anonymousUserNavigation;
  if (loggedInUser) {
    userNavigation = authenticatedUserNavigation;
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

// export default function Nav () {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showButton2, setShowButton2] = useState(true);
  const [isDisclosureClicked, setIsDisclosureClicked] = useState(false);

  const handleDisclosureClick = () => {
    setIsDisclosureClicked(true);
    setTimeout(() => {
      setIsDisclosureClicked(false);
    }, 2000);
    window.scrollBy({ top: 10, left: 0, behavior: 'smooth' });

  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
 };
  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
 };

  const logOut = () => {
      window.localStorage.removeItem('loggedInUser');
      navigate('/');
  }

 useEffect(() => {
       const handleScroll = () => {
         const navs = document.getElementById("navs");
         if (window.scrollY >= 10) {
           if (navs) {
             navs.style.padding = "20px";
             navs.style.background = "#111827";
             navs.style.transition = "padding 0.4s, background 0.4s";
           }
         } else {
           if (navs) {
             navs.style.padding = "0";
             navs.style.background = "transparent";
             navs.style.transition = "padding 0.4s, background 0.4s";
           }
       }
       };

       window.addEventListener("scroll", handleScroll);

       return () => {
         window.removeEventListener("scroll", handleScroll);
       };
     }, []);


  return (
    <>
      <nav className="-my-24 bg-transparent 2xl:max-w-7xl z-50 fixed container mx-auto w-full flex-col pr-8 md:pr-16 2xl:pr-14">
        <Disclosure as="nav" className={`flex flex-col relative w-full mt-4 justify-between items-center rounded-lg ${isDisclosureClicked ? 'bg-gray-900 p-4' : 'bg-transparent'}`} id="navs">
          {({ open }) => (
            <>
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center">
                  <Link to="/">
                    <img src={Logo} alt="Logo" className="h-5" />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">

                    <Menu as="div" className="relative ml-3">
                      <div className='items-center gap-4 flex'>
                      {/*<Notification*/}
                      {/*  title="Verify Identity"*/}
                      {/*  color="yellow"*/}
                      {/*  >*/}
                      {/*  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">*/}
                      {/*    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.78682 1.996C7.32568 1.06266 8.67283 1.06267 9.21169 1.996L14.2346 10.696C14.7735 11.6293 14.0999 12.796 13.0222 12.796H2.97631C1.89858 12.796 1.22501 11.6293 1.76387 10.696L6.78682 1.996ZM7.99922 4C8.33059 4 8.59922 4.26863 8.59922 4.6V7.4C8.59922 7.73137 8.33059 8 7.99922 8C7.66785 8 7.39922 7.73137 7.39922 7.4L7.39922 4.6C7.39922 4.26863 7.66785 4 7.99922 4ZM7.99922 11.2C8.44105 11.2 8.79922 10.8418 8.79922 10.4C8.79922 9.95817 8.44105 9.6 7.99922 9.6C7.55739 9.6 7.19922 9.95817 7.19922 10.4C7.19922 10.8418 7.55739 11.2 7.99922 11.2Z" fill="#FCD34D"/>*/}
                      {/*  </svg>*/}
                      {/*</Notification>*/}
                      {!isConnected ? (
                        <Button onClick={openModal} variant="transparent" variantType="button">
                            <IconEthereum/>
                          Connect Wallet
                        </Button>
                      ) : (
                        <WalletComponent />
                      )}
                      <Modal
                        title="Connect Wallet"
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        img="defaultImage.jpg"
                        text="Default Text"
                        data={blockData}
                        onClick={() => {
                          closeModal();
                          setShowButton(false);
                        }}
                        >
                      </Modal>
                        <Menu.Button className="relative border-2 p-1 rounded-lg border-transparent focus:border-violet-600">
                          <span className="absolute -inset-1.5" />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M15.7508 6C15.7508 8.07107 14.0719 9.75 12.0008 9.75C9.92975 9.75 8.25082 8.07107 8.25082 6C8.25082 3.92893 9.92975 2.25 12.0008 2.25C14.0719 2.25 15.7508 3.92893 15.7508 6Z"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.50195 20.1182C4.57226 16.0369 7.90269 12.75 12.0008 12.75C16.099 12.75 19.4295 16.0371 19.4997 20.1185C17.2169 21.166 14.6772 21.75 12.0011 21.75C9.32481 21.75 6.78491 21.1659 4.50195 20.1182Z"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 p-1 mt-2 w-60 overflow-hidden origin-top-right rounded-lg bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-700' : '',
                                    'text-sm block px-3 py-2 text-gray-50 hover:bg-gray-700 hover:text-white rounded'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                <Disclosure.Button
                  onClick={handleDisclosureClick}
                  className={`relative inline-flex items-center justify-center rounded-md p-2 text-white ${open ? 'menu-button-animation open' : 'menu-button-animation'}`}
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden flex flex-col w-full">
                  <div className="">

                  {showButton2 ? (
                        <button onClick={openModal} className='w-full flex items-center gap-3 text-base text-gray-400 hover:text-gray-300 py-7 border-b border-gary-800 border-px-8'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.64222 10.442L11.9994 4.52866L16.3566 10.442C15.4036 10.9715 14.4869 11.5543 13.5551 12.1467C13.0439 12.4717 12.5282 12.7995 11.9995 13.123C11.4708 12.7996 10.9552 12.4718 10.4441 12.1468L10.4441 12.1468C9.51216 11.5544 8.59532 10.9715 7.64222 10.442ZM7.00603 11.8033C7.01206 11.814 7.01851 11.8245 7.02538 11.8348L11.9994 19.2958L16.9735 11.8348C16.9803 11.8245 16.9868 11.814 16.9928 11.8033C16.1507 12.2762 15.3264 12.8 14.4516 13.3559C13.8778 13.7205 13.2824 14.0989 12.6459 14.4857C12.2488 14.7271 11.7502 14.7271 11.353 14.4857C10.7167 14.0989 10.1213 13.7206 9.54761 13.3561C8.67269 12.8001 7.84826 12.2762 7.00603 11.8033ZM5.77731 12.6668C5.31039 11.9665 5.33197 11.0487 5.8313 10.371L11.5969 2.54629C11.7967 2.27509 12.2021 2.27509 12.4019 2.54629L18.1675 10.371C18.6669 11.0487 18.6885 11.9664 18.2215 12.6668L12.4154 21.376C12.2175 21.6728 11.7813 21.6728 11.5834 21.376L5.77731 12.6668Z" fill="#9CA3AF"/>
                        </svg>
                        Connect Wallet
                      </button>
                      ) : (
                        <div className="">
                        <button onClick={openModal2} className='w-full flex items-center justify-between border-b border-gary-800 border-px-8'>
                          <div className="flex items-center gap-3 text-base text-gray-400 hover:text-gray-300 py-7">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64222 8.44189L6.99942 2.52855L11.3566 8.4419C10.4036 8.97137 9.48688 9.55419 8.55506 10.1466C8.0439 10.4716 7.52819 10.7994 6.99948 11.1229C6.47084 10.7994 5.9552 10.4716 5.44409 10.1467L5.44408 10.1467C4.51216 9.55426 3.59532 8.9714 2.64222 8.44189ZM2.00603 9.80319C2.01206 9.81386 2.01851 9.82435 2.02538 9.83466L6.99942 17.2957L11.9735 9.83466C11.9803 9.82436 11.9868 9.81387 11.9928 9.80322C11.1507 10.2761 10.3264 10.7999 9.45158 11.3558C8.87783 11.7204 8.28237 12.0988 7.64594 12.4856C7.24881 12.727 6.75016 12.727 6.35303 12.4856C5.71668 12.0988 5.12129 11.7205 4.54761 11.3559C3.67269 10.8 2.84826 10.2761 2.00603 9.80319ZM0.777307 10.6667C0.310389 9.96634 0.331971 9.04857 0.831295 8.37091L6.59689 0.546174C6.79673 0.274974 7.20212 0.274973 7.40195 0.546174L13.1675 8.37091C13.6669 9.04857 13.6885 9.96634 13.2215 10.6667L7.41545 19.3758C7.21754 19.6727 6.78131 19.6727 6.5834 19.3758L0.777307 10.6667Z" fill="#9CA3AF"/>
                            </svg>
                            Wallet
                          </div>
                          <div className="text-base text-gray-200 font-medium">
                            14.0004 ETH
                          </div>
                        </button>
                        <ModalWallet
                          title="Wallet"
                          isOpen={isModalOpen2}
                          onClose={closeModal2}
                        />
                      </div>
                      )}
                  <Modal
                    id="modalid"
                    title="Connect Wallet"
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    img="defaultImage.jpg"
                    text="Default Text"
                    data={blockData}
                    onClick={() => {
                      closeModal();
                      setShowButton2(false);
                    }}
                    >
                  </Modal>
                  </div>


                  <div className="mt-3 space-y-1 w-full">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="rounded-md flex py-2 gap-3 text-base font-medium text-gray-400 hover:text-gray-300 w-full"
                      > {item.svg && (
                        <span className="">
                          {item.svg}
                        </span>
                      )}
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </nav>
    </>
  )
}
