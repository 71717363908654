import React, {useState} from "react";
import { Footer } from "../new-component/footer";
import Containers from "../new-component/containers";
import ItemList from "../new-component/itemList";
import Select from "../new-component/select";
import SearchForm from "../new-component/searchForm";
import HeaderCollection from "../new-component/headerCollection";
import Nav from "../components/nav";
import InfoBox from "../new-component/infoBox";
import {useGetCollectionInfo} from "../hooks/useGetCollectionInfo";
import {useParams} from "react-router-dom";
import Backgrounds from "../components/backgrounds";


const variant = [
  { value: 'price', name: 'Price low to high' },
  { value: 'price,DESC', name: 'Price high to low' },
  { value: 'updatedAt,DESC', name: 'Recently listed' },
  { value: 'viewed,DESC', name: 'Most Viewed' },
];

export const CollectionPage: React.FC = () => {
  const { collectionAddress } = useParams();
  const collectionInfo = useGetCollectionInfo(collectionAddress);

  const [sortBy, setSotBy] = useState('price');
  const handleSelectClick = (event: { target: any; }, sortBy: any) => {
    setSotBy(sortBy);
  };

  return (collectionInfo &&
      <Backgrounds>
        <Containers>
          <Nav/>
          <HeaderCollection
            img={collectionInfo.fileUrl}
            text={collectionInfo.name}
            description={collectionInfo.description}
          />
          <InfoBox
            totalVolume={collectionInfo.totalVolume}
            privatelyOwned={collectionInfo.privatelyOwned}
            floorPrice={collectionInfo.floorPrice}
          />
          <div className="flex flex-col relative items-end gap-4 md:flex-row md:justify-between">
            <SearchForm/>
            <Select options={variant} handleSelectClick={handleSelectClick} />
          </div>

          <ItemList
            variantSize="deflaut"
            buttonText="Show More"
            initialItemsToShow={12}
            itemData={5}
            showNumConfig={{ smallScreen: 4, mediumScreen: 6, largeScreen: 12 }}
            sort={sortBy}
            />
        </Containers>
      <Footer />
      </Backgrounds>
  );
};
