import React, { useState } from 'react';
import Button from "../new-component/button";
import 'react-datepicker/dist/react-datepicker.css';

interface ModalPlaceBidProps {
  titleModal: string;
  statusPrice?: string;
  badgeText: string;
  price?: string;
  itemName: string;
  isOpen: boolean;
  img: string;
  auctionTitleTime: string;
  dot?: boolean;
  onClose: () => void;
  auctionTime: string;
  auctionBidders: string;
  auctionBids: string;
}

const ModalPlaceBid: React.FC<ModalPlaceBidProps> = ({ 
  isOpen, 
  onClose, 
  titleModal, 
  statusPrice, 
  price,
  badgeText,
  itemName,
  img,
  auctionTitleTime,
  dot = false,
  auctionTime,
  auctionBidders,
  auctionBids,
 }) => {
  
  if (!isOpen) return null;

  return (
    <div className="z-50 fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-950 bg-opacity-50 px-4">
      <div className="relative rounded-xl p-4 md:p-6 bg-gray-900 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-between items-center">
          <div className="text-2xl text-gray-50 font-medium">{titleModal}</div>
          <button onClick={onClose} className="top-2 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z" fill="#D1D5DB" />
            </svg>
          </button>
        </div>
        <div className="flex gap-4 items-center pt-6">
          <img className="w-[72px] h-[72px] rounded-md" src={img} alt="" />
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col gap-1 items-start">
              <div className="text-violet-400 text-sm font-normal">{badgeText}</div>
              <div className="text-white text-base md:text-lg font-medium">{itemName}</div>
            </div>
            <div className="flex flex-col items-end gap-1">
              {statusPrice &&(
                <div className="text-gray-400 text-sm font-normal">{statusPrice}</div>
              )}
              <div className="font-medium text-base md:text-lg text-gray-300">{price}</div>
            </div>
          </div>
        </div>
        {auctionTitleTime && dot !== undefined && auctionTime && auctionBidders && auctionBids && (
          <div className="flex flex-wrap my-4 md:my-8 items-center gap-8">
          
            <div className="flex flex-col items-start gap-0.5">
              <div className="text-gray-400 text-sm font-normal">{auctionTitleTime}</div>
              <div className="flex items-center gap-1">
                { dot && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <circle opacity="0.2" cx="10.5" cy="10" r="5" fill="#34D399"/>
                    <circle cx="10.5" cy="10" r="3" fill="#34D399"/>
                  </svg>
                )}
                <div className="text-base text-white font-medium">{auctionTime}</div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-0.5">
              <div className="text-gray-400 text-sm font-normal">Bids</div>
              <div className="text-base text-white font-medium">{auctionBids}</div>
            </div>

            <div className="flex flex-col items-start gap-0.5">
              <div className="text-gray-400 text-sm font-normal">Bidders</div>
              <div className="text-base text-white font-medium">{auctionBidders}</div>
            </div>

          </div>
        )}
        <form action="" className="pb-2" id="inputContainer">
          <div className="flex">
            <div className="w-full">
              <label htmlFor="number" className="block text-sm text-left font-medium text-gray-400">Your maximum bid</label>
              <div className="flex mt-2 w-full items-center gap-4">
                <div className="flex w-full">
                  <input id="number" type="number" required className="outline-none block w-full rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2 text-white text-base font-normal" />
                </div>
                <div className="text-gray-400 font-medium text-lg">ETH</div>
              </div>
              <div className="text-gray-500 text-sm font-normal mt-1.5 text-start w-72">Enter 0.6 ETH or more, with $100.00 USD increments.</div>
            </div>
          </div>
          <div className="mt-7">
            <Button
              variant="secondary"
              variantType='button'
              variantSize='full'
              type='submit'
              isDisabled
            >
              Place Bid
            </Button>
          </div>
          
        </form>
        <div className="text-gray-500 text-sm font-normal text-center">By selecting “Place Bid”, you are committing to buy this item if you are the winning bidder.</div>
      </div>
    </div>
  );
};

export default ModalPlaceBid;
