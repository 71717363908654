import React from "react";
import ItemList from "../new-component/itemList";
import Search from "../new-component/searchForm";
import Select from "../new-component/select";

const variant = [
  { id: 1, name: 'Price low to high' },
  { id: 2, name: 'Price high to low' },
  { id: 3, name: 'Recently listed' },
  { id: 4, name: 'Most Viewed' },
];

const OwnedAssets: React.FC = () => {
  return (
    <div className="">
      <div className="flex items-end flex-col md:flex-row md:justify-between gap-4">
        <Search/>
        {/*<Select variant={variant}/>*/}
      </div>
      <ItemList
        buttonText="Show More"
        variantSize="deflaut"
        initialItemsToShow={4}
        itemData={5}
        showNumConfig={{ smallScreen: 4, mediumScreen: 6, largeScreen: 8 }}
        sort="id"
      />
    </div>
  );
};

export default OwnedAssets;
