import React from 'react';

interface InfoBoxProps {
  totalVolume: string;
  privatelyOwned: string;
  floorPrice: string;
}

const InfoBox: React.FC<InfoBoxProps> = (props) => {
  const { totalVolume, privatelyOwned, floorPrice } = props;
  return (
    <div className="inline-flex gap-4 md:gap-12 flex-wrap justify-start pb-12 items-center">
      <div className="pr-12 md:pr-0">
        <div className="text-gray-50 font-semibold text-lg">
          {totalVolume}
        </div>
        <div className="text-gray-500 font-normal text-base">
          Total Volume
        </div>
      </div>
      <div className="pr-12 md:pr-0">
        <div className="text-gray-50 font-semibold text-lg">
          {floorPrice}
        </div>
        <div className="text-gray-500 font-normal text-base">
          Floor Price
        </div>
      </div>
      <div className="">
        <div className="text-gray-50 font-semibold text-lg">
          {privatelyOwned}
        </div>
        <div className="text-gray-500 font-normal text-base">
          Privately Owned
        </div>
      </div>
    </div>
  );
}

export default InfoBox;
