import { useEffect, useState } from "react";

export type GroupedCollections = {
  categoryId: number;
  categoryName: string;
  collections: LightCollection[];
};

export type LightCollection = {
  name: string;
  permalink: string;
  description: string;
  fileUrl: string;
  totalVolume: string;
  totalItems: string;
};

export function useGetGroupedCollections(): GroupedCollections[] {
  const [groupedCollections, setGroupedCollections] = useState<GroupedCollections[]>([]);

  useEffect(() => {
    const getGroupedCollections = async () => {
      fetch('https://api.qentare.com/v1/grouped')
          .then(response => {
            return response.json()
          })
          .then(data => {
            setGroupedCollections(data)
          })
    };

    getGroupedCollections();
  }, [false]);

  return groupedCollections;
}
