import React from "react";
import { BrowserRouter as Router, Route, NavLink, Routes } from "react-router-dom";
import Nav from "../components/nav";
import { Footer } from "../new-component/footer";
import Backgrounds from "../components/backgrounds";
import Containers from "../new-component/containers";
import HeaderProfile from "../new-component/headderProfile";
import InfoCard from '../new-component/infoCard';
import Button from "../new-component/button";
import OwnedAssets from "../new-component/OwnedAssets";
import TabelXL from "../new-component/TabelXL";
import PreviousActivity from "../new-component/ItemsNotFound";
import Saved from "../new-component/Saved";


// --------------------------------------------OffersMade
const tableOffersMade = [
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
];
// --------------------------------------------End OffersMade

// --------------------------------------------OffersReceived
const tableOffersReceived = [
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
  {
    img: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdVeaBHG14KeNI0qyRsOLVw5lGkX9HRULJEVcoSsTWnyy7TDXo",
    itemName: 'Item Name',
    collectionName: 'Collection Name',
    price: '14.254 ETH',
    usdPrice: '$23 510,41',
    expiration: 'in 4 hours',
    created: '5 days ago',
  },
];
// --------------------------------------------End OffersReceived


const Profile: React.FC = () => {
  // @ts-ignore
  const loggedInUser = JSON.parse(window.localStorage.getItem('loggedInUser')) || undefined;

  return (
    <Backgrounds>
        <Containers>
          <Nav />
          <InfoCard
            title="We need to verify your identity"
            text="Before you can start investing in real estate, you have to verify your identity."
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.40087 3.0034C10.5554 1.00229 13.4435 1.00229 14.598 3.0034L21.9523 15.7509C23.1062 17.7509 21.6627 20.2501 19.3538 20.2501H4.6451C2.33612 20.2501 0.892695 17.7509 2.04654 15.7509L9.40087 3.0034ZM11.9996 8.25C12.4138 8.25 12.7496 8.58579 12.7496 9V12.75C12.7496 13.1642 12.4138 13.5 11.9996 13.5C11.5854 13.5 11.2496 13.1642 11.2496 12.75V9C11.2496 8.58579 11.5854 8.25 11.9996 8.25ZM11.9996 16.5C12.4138 16.5 12.7496 16.1642 12.7496 15.75C12.7496 15.3358 12.4138 15 11.9996 15C11.5854 15 11.2496 15.3358 11.2496 15.75C11.2496 16.1642 11.5854 16.5 11.9996 16.5Z" fill="#FCD34D" />
              </svg>
            }
          >
            <Button
              variant="primary"
              variantType="button"
              variantSize="block"
            >
              Verify Identity
            </Button>
          </InfoCard>
          <HeaderProfile userName={loggedInUser.lastName + " " + loggedInUser.firstName + " (" + loggedInUser.email + ")"} userAddress="0x50Ba...cA3A">
            <Button variant='link' variantType='link' variantSize='block' to='/Setting'>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.84019 1.80388C7.93368 1.33646 8.34409 1 8.82077 1H11.1812C11.6578 1 12.0683 1.33646 12.1617 1.80388L12.4922 3.45629C13.1965 3.72458 13.8464 4.10332 14.4205 4.57133L16.0188 4.03065C16.4704 3.8779 16.967 4.06509 17.2053 4.47791L18.3855 6.52207C18.6238 6.93489 18.5377 7.45855 18.1796 7.77322L16.9129 8.88645C16.9709 9.24909 17.001 9.62103 17.001 10C17.001 10.379 16.9709 10.7509 16.9129 11.1135L18.1796 12.2268C18.5377 12.5414 18.6238 13.0651 18.3855 13.4779L17.2053 15.5221C16.967 15.9349 16.4704 16.1221 16.0188 15.9693L14.4206 15.4287C13.8464 15.8967 13.1965 16.2754 12.4922 16.5437L12.1617 18.1961C12.0683 18.6635 11.6578 19 11.1812 19H8.82077C8.34409 19 7.93368 18.6635 7.84019 18.1961L7.50971 16.5437C6.8054 16.2754 6.15557 15.8967 5.5814 15.4287L3.98311 15.9694C3.53157 16.1221 3.03498 15.9349 2.79663 15.5221L1.61644 13.4779C1.3781 13.0651 1.46427 12.5415 1.82233 12.2268L3.08905 11.1135C3.03109 10.7509 3.00097 10.379 3.00097 10C3.00097 9.62103 3.03109 9.2491 3.08905 8.88647L1.82233 7.77324C1.46427 7.45857 1.3781 6.93491 1.61644 6.52209L2.79664 4.47793C3.03498 4.06511 3.53157 3.87791 3.98311 4.03066L5.58139 4.57134C6.15556 4.10332 6.80539 3.72459 7.50971 3.45629L7.84019 1.80388ZM10.001 13C11.6578 13 13.001 11.6569 13.001 10C13.001 8.34315 11.6578 7 10.001 7C8.34412 7 7.00097 8.34315 7.00097 10C7.00097 11.6569 8.34412 13 10.001 13Z" fill="#D1D5DB" />
              </svg>
            </Button>
          </HeaderProfile>

          <div className="">
            <div className="flex gap-2 items-center whitespace-nowrap w-full overflow-x-scroll no-scrollbar mb-8 md:mb-14">
              <NavLink className="text-gray-300 text-base font-medium py-2 px-3 focus:bg-gray-900 focus:rounded-full focus:text-gray-50" to="owned-assets">Owned Assets</NavLink>
              <NavLink className="text-gray-300 text-base font-medium py-2 px-3 focus:bg-gray-900 focus:rounded-full focus:text-gray-50" to="offers-made">Offers Made</NavLink>
              <NavLink className="text-gray-300 text-base font-medium py-2 px-3 focus:bg-gray-900 focus:rounded-full focus:text-gray-50" to="offers-received">Offers Received</NavLink>
              <NavLink className="text-gray-300 text-base font-medium py-2 px-3 focus:bg-gray-900 focus:rounded-full focus:text-gray-50" to="saved">Saved</NavLink>
              <NavLink className="text-gray-300 text-base font-medium py-2 px-3 focus:bg-gray-900 focus:rounded-full focus:text-gray-50" to="previous-activity">Previous Activity</NavLink>
            </div>
          </div>
          <div className="pb-20">
            <Routes>
              <Route path="owned-assets" element={<OwnedAssets />} />
              <Route path="offers-made" element={
              <TabelXL tableData={tableOffersMade}>
                <Button variant="icon" variantType="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M14.7404 9L14.3942 18M9.60577 18L9.25962 9M19.2276 5.79057C19.5696 5.84221 19.9104 5.89747 20.25 5.95629M19.2276 5.79057L18.1598 19.6726C18.0696 20.8448 17.0921 21.75 15.9164 21.75H8.08357C6.90786 21.75 5.93037 20.8448 5.8402 19.6726L4.77235 5.79057M19.2276 5.79057C18.0812 5.61744 16.9215 5.48485 15.75 5.39432M3.75 5.95629C4.08957 5.89747 4.43037 5.84221 4.77235 5.79057M4.77235 5.79057C5.91878 5.61744 7.07849 5.48485 8.25 5.39432M15.75 5.39432V4.47819C15.75 3.29882 14.8393 2.31423 13.6606 2.27652C13.1092 2.25889 12.5556 2.25 12 2.25C11.4444 2.25 10.8908 2.25889 10.3394 2.27652C9.16065 2.31423 8.25 3.29882 8.25 4.47819V5.39432M15.75 5.39432C14.5126 5.2987 13.262 5.25 12 5.25C10.738 5.25 9.48744 5.2987 8.25 5.39432" stroke="#E5E7EB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </Button>
                <Button variant="icon" variantType="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M20.3996 3.30039C20.3996 2.80333 19.9967 2.40039 19.4996 2.40039C19.0026 2.40039 18.5996 2.80333 18.5996 3.30039V9.90039C18.5996 10.3974 19.0026 10.8004 19.4996 10.8004C19.9967 10.8004 20.3996 10.3974 20.3996 9.90039V3.30039Z" fill="#E5E7EB"/>
                    <path d="M20.3996 18.9004C20.3996 18.4033 19.9967 18.0004 19.4996 18.0004C19.0026 18.0004 18.5996 18.4033 18.5996 18.9004V20.7004C18.5996 21.1974 19.0026 21.6004 19.4996 21.6004C19.9967 21.6004 20.3996 21.1974 20.3996 20.7004V18.9004Z" fill="#E5E7EB"/>
                    <path d="M4.49961 18.0004C4.99667 18.0004 5.39961 18.4033 5.39961 18.9004V20.7004C5.39961 21.1974 4.99667 21.6004 4.49961 21.6004C4.00255 21.6004 3.59961 21.1974 3.59961 20.7004V18.9004C3.59961 18.4033 4.00255 18.0004 4.49961 18.0004Z" fill="#E5E7EB"/>
                    <path d="M5.39961 3.30039C5.39961 2.80333 4.99667 2.40039 4.49961 2.40039C4.00255 2.40039 3.59961 2.80333 3.59961 3.30039V9.90039C3.59961 10.3974 4.00255 10.8004 4.49961 10.8004C4.99667 10.8004 5.39961 10.3974 5.39961 9.90039V3.30039Z" fill="#E5E7EB"/>
                    <path d="M11.9996 13.2004C12.4967 13.2004 12.8996 13.6033 12.8996 14.1004V20.7004C12.8996 21.1974 12.4967 21.6004 11.9996 21.6004C11.5026 21.6004 11.0996 21.1974 11.0996 20.7004V14.1004C11.0996 13.6033 11.5026 13.2004 11.9996 13.2004Z" fill="#E5E7EB"/>
                    <path d="M12.8996 3.30039C12.8996 2.80333 12.4967 2.40039 11.9996 2.40039C11.5026 2.40039 11.0996 2.80333 11.0996 3.30039V5.10039C11.0996 5.59745 11.5026 6.00039 11.9996 6.00039C12.4967 6.00039 12.8996 5.59745 12.8996 5.10039V3.30039Z" fill="#E5E7EB"/>
                    <path d="M11.9996 7.20039C10.6741 7.20039 9.59961 8.27491 9.59961 9.60039C9.59961 10.9259 10.6741 12.0004 11.9996 12.0004C13.3251 12.0004 14.3996 10.9259 14.3996 9.60039C14.3996 8.27491 13.3251 7.20039 11.9996 7.20039Z" fill="#E5E7EB"/>
                    <path d="M4.49961 12.0004C3.17413 12.0004 2.09961 13.0749 2.09961 14.4004C2.09961 15.7259 3.17413 16.8004 4.49961 16.8004C5.82509 16.8004 6.89961 15.7259 6.89961 14.4004C6.89961 13.0749 5.82509 12.0004 4.49961 12.0004Z" fill="#E5E7EB"/>
                    <path d="M19.4996 12.0004C18.1741 12.0004 17.0996 13.0749 17.0996 14.4004C17.0996 15.7259 18.1741 16.8004 19.4996 16.8004C20.8251 16.8004 21.8996 15.7259 21.8996 14.4004C21.8996 13.0749 20.8251 12.0004 19.4996 12.0004Z" fill="#E5E7EB"/>
                  </svg>
                </Button>
              </TabelXL>} />
              <Route path="offers-received" element={
              <TabelXL  tableData={tableOffersReceived}>
                <Button variant="icon" variantType="button">
                  Decline
                </Button>
                <Button variant="secondary" variantType="button">
                  Accept
                </Button>
              </TabelXL>} />
              <Route path="saved" element={<Saved />} />
              <Route path="previous-activity" element={<PreviousActivity>
                <Button variant="primary" variantType="button">
                Explore Real Estate
                </Button>
              </PreviousActivity>} />
            </Routes>
          </div>

        </Containers>
      <Footer/>
    </Backgrounds>
  );
};

export default Profile;
