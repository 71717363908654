import React from 'react';
import GoogleMapReact from "google-map-react";

type Coord = {
  lat: number;
  lng: number;
}

type ItemProps = {
  itemPolygon: Coord[];
  collectionPolygon: Coord[];
  center: Coord;
  name: string;
}

const handleApiLoaded = (map: any, maps: any, itemCoords: Coord[], collectionCoords: Coord[], center: Coord, name: string) => {
  if (itemCoords) {
    var itemPolygon = new maps.Polygon({
      paths: itemCoords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35
    });
    itemPolygon.setMap(map);
  }
  if (collectionCoords) {
    var collectionPolygon = new maps.Polygon({
      paths: collectionCoords,
      strokeColor: "#63b175",
      strokeOpacity: 0.5,
      strokeWeight: 1,
      fillColor: "#63b175",
      fillOpacity: 0.35
    });
    collectionPolygon.setMap(map);
  }
  var marker = new maps.Marker({
    position: center,
    map,
    title: name,
  });
  marker.setMap(map);
}

const GoogleMapComponent: React.FC<ItemProps> = ({ itemPolygon, collectionPolygon, center, name }) => {
  return (
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyCFM4QofDvW-NtazG0KADuN_y2gKYop2kk" }}
            defaultCenter={{lat: collectionPolygon[0].lat, lng: collectionPolygon[0].lng}}
            defaultZoom={14}
            options={{mapTypeId: 'terrain'}}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, itemPolygon, collectionPolygon, center, name)}
        >
        </GoogleMapReact>
      </div>
  );
};

export default GoogleMapComponent;
