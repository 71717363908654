import * as React from "react";
import { CollectionsHeader } from "./collectionsHeader";
import { Collection } from "./collection";
import OwlCarousel from "react-owl-carousel";
import {CollectionModel} from "../hooks/useGetCollections";

interface CollectionHeaderProps {
    title: string;
    id: string;
    items: CollectionModel[];
    type: 'text-on' | 'text-under' | 'full-width';
}

export const MarketplaceCollections: React.FC<CollectionHeaderProps> = ({
    title,
    id,
    items,
    type
}) => {
    return ( items.length &&
        <div id="collections-carousel-container" className="">
            <CollectionsHeader title={title} id={id}/>
            <OwlCarousel
                className='owl-theme py-10 pl-24 sm:pl-20 md:pl-14 lg:pl-6 xl:pl-0'
                loop={false}
                margin={20}
                center={true}
                nav={false}
                dots={false}
                navText={[
                    '<div class="top-0 -left-4 h-full items-center md:flex"><div class="prev px-1.5 py-1.5 rounded bg-gray-900 bg-opacity-80 group" aria-label="prev"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.7906 5.23017C13.0777 5.52875 13.0684 6.00353 12.7698 6.29063L8.83208 10L12.7698 13.7094C13.0684 13.9965 13.0777 14.4713 12.7906 14.7698C12.5035 15.0684 12.0287 15.0777 11.7302 14.7906L7.23017 10.5406C7.08311 10.3992 7 10.204 7 10C7 9.79599 7.08311 9.60078 7.23017 9.45938L11.7302 5.20938C12.0287 4.92228 12.5035 4.93159 12.7906 5.23017Z" fill="#6B7280"/></svg></div></div>',
                    '<div class="top-0 -right-4 h-full items-center md:flex"><div class="next px-1.5 py-1.5 rounded bg-gray-900 bg-opacity-80 group" aria-label="next"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z" fill="#6B7280"/></svg></div></div>'
                ]}
                navContainer={'#' + id}
                responsive={{
                    '360': {
                        items: 1
                    },
                    '640': {
                        items: 2
                    },
                    '768': {
                        items: 2.5
                    },
                    '1000': {
                        items: 4,
                    },
                    '1280': {
                        items: 4.5,
                    },
                }}
            >
                {
                    items.map(collection => (
                        <div>
                            <div className="item">
                                <Collection key={"popular-collection-"+ collection.id} type={type} title={collection.name} shortDescription={collection.description} permalink={collection.permalink} fileUrl={collection.fileUrl}/>
                            </div>
                        </div>
                    ))
                }
            </OwlCarousel>

        </div>
    );
};
