import React from 'react';
import Logo from '../img/Logo (1).svg';

export const Footer = () => {
  return (
    <footer className="bg-gray-900 relative mt-16 md:mt-24 xl:mt-28" aria-labelledby="footer-heading">
    <div className="container px-4 md:px-8 mx-auto py-12 md:py-16">
      <div className="flex flex-col md:flex-row md:justify-between 2xl:max-w-7xl 2xl:mx-auto">
        <div className="space-y-6 sm:w-1/2 xl:w-1/4">
          <img className="h-8" src={Logo} alt="Logo SVG" />
          <p className="text-base leading-6 text-gray-400">Making the world a better place through constructing elegant hierarchies.</p>
        </div>
        <div className="grid xl:grid-cols-2 xl:gap-20 py-12 md:py-0 md:pb-12">
          <div className="grid grid-cols-2 md:gap-16 xl:gap-20">
            <div>
              <h3 className="tracking-wider text-sm font-semibold leading-5 text-gray-400 uppercase">Marketplace</h3>
              <ul role="list" className="mt-2 space-y-1 md:space-y-2">
                <li>
                  <a href="" className="text-sm leading-6 text-gray-300 hover:text-white">Home</a>
                </li>
                <li>
                  <a href="" className="text-sm leading-6 text-gray-300 hover:text-white">Collections</a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="tracking-wider text-sm font-semibold leading-5 text-gray-400 uppercase">Account</h3>
              <ul role="list" className="mt-2 space-y-1 md:space-y-2">
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Dashboard</a>
                </li>
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Account Settings</a>
                </li>
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Wallet</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-2 md:gap-16 xl:gap-20">
            <div className="mt-2 xl:mt-0">
              <h3 className="tracking-wider text-sm font-semibold leading-6 text-gray-400 uppercase">resources</h3>
              <ul role="list" className="mt-2 space-y-1 md:space-y-2">
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">About Qentare</a>
                </li>
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Support</a>
                </li>
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Status</a>
                </li>
              </ul>
            </div>
            <div className="mt-10 xl:mt-0">
              <h3 className="tracking-wider text-sm font-semibold leading-6 text-gray-400 uppercase">Legal</h3>
              <ul role="list" className="mt-2 space-y-1 md:space-y-2">
                <li>
                  <a href="#" className="text-sm leading-1 text-gray-300 hover:text-white">Privacy Policy</a>
                </li>
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Terms of Service</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-white/10 pt-8">
        <p className="md:text-center text-base leading-5 text-gray-500">&copy; 2023 Qentare. All rights reserved.</p>
      </div>
    </div>
  </footer>
  );
};
