import { useEffect, useState } from "react";

export type LightCollection = {
  name: string,
  description: string,
  fileUrl: string,
  totalVolume: string,
  floorPrice: string,
  privatelyOwned: string
};

export function useGetCollectionInfo(collectionAddress: string | undefined): LightCollection {
  const [collection, setCollection] = useState<LightCollection>();

  useEffect(() => {
    const getCollection = async () => {
      fetch(`https://marketplace.qentare.com/v1/collections/${collectionAddress}/info`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setCollection(data)
          })
    };

    getCollection();
  }, [collectionAddress]);

  return collection as LightCollection;
}
