import React, { useState } from 'react';

interface InputProps {
  label: string;
  id: string;
  name: string;
  type?: string;
  required: boolean;
}

interface PasswordInputProps extends InputProps {
  icon?: React.ReactNode;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<PasswordInputProps> = ({
  label,
  id,
  name,
  type,
  required,
  icon,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-400">
          {label}
        </label>
      </div>

      <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
        <input
          id={id}
          name={name}
          type={type || showPassword ? 'text' : type}
          required={required}
          className="outline-none w-full text-gray-500 text-base font-normal"
          style={{ background: 'none' }}
        />
        {icon && (
          <div className="absolute right-0 mr-2 cursor-pointer" onClick={togglePasswordVisibility}>
            {icon}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
