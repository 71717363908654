import React, { ReactNode } from 'react';
import Graphic1 from '../img/Graphic_1.svg';
import Graphic2 from '../img/Graphic_2.svg';

interface ContainerProps {
  children: ReactNode;
}

const Backgrounds: React.FC<ContainerProps> = ({ children }) => {
  return (
    <div className="relative bg-gray-950 overflow-hidden h-full">
      <div className="absolute -z-1 pointer-events-none w-full">
        <img className="flex w-full opacity-10" src={Graphic2} alt="Graphic2" />
      </div>
      
      {children}

      <div className="absolute top-[calc(100%-66%)] md:top-[calc(100%-65%)] xl:bottom-[calc(50%-75%)] -z-1 pointer-events-none">
        <img className="opacity-25 w-[188px] md:w-[384px] xl:w-[679px]" src={Graphic1} alt="Graphic1" />
      </div>
    </div>
  );
};

export default Backgrounds;
