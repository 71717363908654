import React, { useState } from 'react';
import Logo from '../img/Logo (1).svg';
import Input from '../new-component/input';
import Button from '../new-component/button';
import { ReactComponent as EyeIcon } from '../img/eye.svg';


const ChangeEmailForms: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setShowPassword((prevShowPassword) => !prevShowPassword);
  // };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <div className="flex min-h-full flex-col justify-center px-4 pt-32 pb-20 md:pt-56 md:pb-64 lg:px-8 bg-gray-950">
      <div className="sm:mx-auto sm:w-full sm:max-w-xs">
        <img className="mx-auto h-8 w-auto" src={Logo} alt="Logo" />
        <div className="mt-8 text-gray-400 text-base font-normal text-center">
          Choose an email address to use to log in into your account.
        </div>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xs">
        <form className="space-y-4" onSubmit={handleSubmit}>

          <Input label='New Email' id="email" name="email" type="email" required/>
          <Input label='Retype New Email' id="email" name="email" type="email" required/>
          <Input label='Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>

          <div className="flex justify-center">
            <Button variant='link' variantType='link' to='/ForgotPassword'>
              Forgot Password?
            </Button>
          </div>

          <div className="space-y-6 pt-4">

          <Button variant='primary' type='submit' variantType='button' variantSize='full'>
            Update Email
          </Button>

          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeEmailForms;
