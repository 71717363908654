import { useEffect, useState } from "react";

export type CollectionModel = {
  id: number;
  chainId: number,
  address: string,
  name: string,
  permalink: string,
  description: string,
  viewed: number,
  fileUrl: string
};

export function useGetCollections(page: number, size: number, sort: string): CollectionModel[] {
  const [collections, setCollections] = useState<CollectionModel[]>([]);

  useEffect(() => {
    const getCollections = async () => {
      fetch(`https://api.qentare.com/v1/collections?page=${page}&size=${size}&sort=${sort}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setCollections(data)
          })
    };

    getCollections();
  }, [false]);

  return collections;
}
