import React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps {
  variant: 'primary' | 'secondary' | 'danger' | 'link' | 'transparent' | 'navLink' | 'icon' | 'tab';
  variantSize?: 'full' | 'block';
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  id?: string;
  onClick?: () => void;
  variantType?: 'button' | 'link';
  to?: string;
  className?: string;
  text?: string;
  active?: boolean;
  isDisabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  variant,
  variantSize = 'full',
  children,
  type = 'button',
  id,
  onClick,
  variantType = 'button',
  to,
  className,
  text,
  active,
  isDisabled
}) => {
  const variants: Record<string, string> = {
    primary: 'bg-violet-700 hover:bg-violet-600 text-white focus:ring-violet-700 justify-center',
    secondary: 'text-white bg-gray-800 hover:bg-gray-700 focus:ring-violet-800 justify-center',
    danger: 'bg-red-500 hover:bg-red-700 text-white focus:ring-red-700 justify-center',
    transparent: 'border border-gray-600 text-gray-200 focus:ring-violet-800 hover:bg-gray-700 justify-center',
    link: 'text-violet-400 hover:text-violet-500 text-xs justify-center',
    navLink: 'text-gray-300 font-normal text-base hover:bg-gray-600 justify-start',
    icon: 'justify-start text-gray-400',
    tab: 'tab-button text-gray-300 text-lg font-medium py-2 px-5 focus:bg-gray-900 hover:bg-gray-900 focus:rounded-xl hover:rounded-xl focus:text-gray-50 flex items-center gap-2'
  };

  const sizeVariants: Record<string, string> = {
    full: 'flex w-full',
    block: 'w-auto',
  };

  const buttonClass = `flex items-center gap-1 focus:outline-none focus:ring-offset-gray-950 focus:ring-2 focus:ring-offset-2 text-base px-4 py-2 rounded-md transition duration-300 ${variants[variant] || ''} ${sizeVariants[variantSize] || ''}`;
  const tabButtonClass = `${variants[variant] || ''} ${sizeVariants[variantSize] || ''} ${active ? 'tab-active' : ''}`;

  if (variant === 'tab') {
    return (
        <button id={id} className={tabButtonClass + ' ' + className} onClick={onClick} disabled={isDisabled}>
          {children}
        </button>
    );
  }

  if (variantType === 'link') {
    return (
      <div className="">
        <Link to={to || '/'} className={buttonClass}>
          {children}
          {text}
        </Link>
      </div>
    );
  }

  return (
    <div className="">
      <button type={type} id={id} className={buttonClass + ' ' + className} onClick={onClick} disabled={isDisabled}>
        {children}
        {text}
      </button>
    </div>
  );
};

export default Button;
