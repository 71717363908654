import React, { useState } from 'react';
import Logo from '../img/Logo (1).svg';
import Input from '../new-component/input';
import Button from '../new-component/button';
import { ReactComponent as EyeIcon } from '../img/eye.svg';
import {ErrorMessage, Field, Form, Formik} from "formik";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

const CreateAccountForms: React.FC = () => {

  const addUser = async (values: FormValues) => {
      console.log(JSON.stringify(values));
    fetch('/api/user/v1/auth', {
    // fetch('http://localhost:8080/v1/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.firstName) errors.firstName = 'First name is required';
    if (!values.lastName) errors.lastName = 'Last name is required';
    if (!values.email) errors.email = 'Email is required';

    return errors;
  };

  const onSubmit = (values: FormValues) => {
    addUser(values);
  };

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  // };

  return (

    <div className="flex min-h-full flex-col justify-center px-4 pt-32 pb-20 md:pt-56 md:pb-64 lg:px-8 bg-gray-950">

      <div className="sm:mx-auto sm:w-full sm:max-w-xs">
        <img className="mx-auto h-8 w-auto" src={Logo} alt="Logo" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xs">

        <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: ''
            }}
            validate={validate}
            onSubmit={onSubmit}>

          {({ errors, touched, isValid, handleChange, handleBlur, values }) => (

            <div className="space-y-4">
              <div>
                <Form>
                  <div className="flex items-center justify-between">
                    <label className="block text-sm font-medium leading-5 text-gray-400">
                      First name
                    </label>
                  </div>
                  <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                    <Field
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder=""
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`outline-none w-full text-gray-500 text-base font-normal ${errors.firstName && touched.firstName ? 'border-red-500' : 'border-gray-300'}`}
                        style={{ background: 'none' }} />
                    <ErrorMessage name="firstName" component="div" className="text-xs text-red-500" />
                  </div>
                </Form>
              </div>
              <div>
                <Form>
                  <div className="flex items-center justify-between">
                    <label className="block text-sm font-medium leading-5 text-gray-400">
                      Last name
                    </label>
                  </div>
                  <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                    <Field
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder=""
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`outline-none w-full text-gray-500 text-base font-normal ${errors.lastName && touched.lastName ? 'border-red-500' : 'border-gray-300'}`}
                        style={{ background: 'none' }} />
                    <ErrorMessage name="lastName" component="div" className="text-xs text-red-500" />
                  </div>
                </Form>
              </div>
              <div>
                <Form>
                  <div className="flex items-center justify-between">
                    <label className="block text-sm font-medium leading-5 text-gray-400">
                      Email
                    </label>
                  </div>
                  <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                    <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder=""
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`outline-none w-full text-gray-500 text-base font-normal ${errors.email && touched.email ? 'border-red-500' : 'border-gray-300'}`}
                        style={{ background: 'none' }} />
                    <ErrorMessage name="email" component="div" className="text-xs text-red-500" />
                  </div>
                </Form>
              </div>
              {/*<div>
                <Form>
                  <button
                      type="submit"
                      disabled={!isValid}
                      className={`px-4 py-2 text-white bg-blue-500 rounded-md ${!isValid ? 'opacity-50' : ''}`}>
                    Sign Up
                  </button>
                </Form>
              </div>*/}

              <div className="flex justify-center">
                <Button variant='link' variantType='link' to='/passwords'>
                  Forgot Password?
                </Button>
              </div>

              <div className="space-y-6 pt-4">
                <Form>
                    <Button
                        variant='primary'
                        type='submit'
                        variantType='button'
                        variantSize='full'
                        isDisabled={!isValid}>
                      Create Account
                    </Button>
                  </Form>
                <Button variant='secondary' type='submit' variantType='link' variantSize='full' to="/sign-in">
                  Sign In
                </Button>
              </div>
            </div>

          )}

        </Formik>

        {/*<form className="space-y-4" onSubmit={handleSubmit}>

          <div className="flex gap-4 items-center">
            <Input label='Name' id="fname" name="fname" type="text" required/>
            <Input label='Surname' id="lname" name="lname" type="text" required/>
          </div>

          <Input label='Email' id="email" name="email" type="email" required/>
          <Input label='Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>

          <div className="flex justify-center">
            <Button variant='link' variantType='link' to='/ForgotPassword'>
              Forgot Password?
            </Button>
          </div>

          <div className="space-y-6 pt-4">
            <Button variant='primary' type='submit' variantType='button' variantSize='full'>
              Create Account
            </Button>
            <Button variant='secondary' type='submit' variantType='link' variantSize='full' to="/sign-in">
              Sign In
            </Button>
          </div>

        </form>*/}

      </div>

    </div>
  );
};

export default CreateAccountForms;
