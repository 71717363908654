import * as React from "react";

interface CollectionHeaderProps {
    title: string;
    id: string;
}

export const CollectionsHeader: React.FC<CollectionHeaderProps> = ({
  title,
    id
}) => {
    return (
    <div className="flex items-center justify-between pt-14 md:pt-[72px] sm:pl-5">
        <div className="text-white text-2xl md:text-3xl xl:text-4xl font-semibold">
            {title}
        </div>
        <div id={id} className="carousel-nav-container flex gap-2"></div>
    </div>
    );
};
