import React, { useState } from "react";
import Button from "./button";
import { Connector, useAccount, useConnect, useEnsAvatar } from "wagmi";
import WalletModalIconComponent from "../img/icons/WalletModalIconComponent";

interface Props {
    connector: Connector<any, any, any>,
    index: number,
    modalClose: () => void;
}

export const WalletConnector: React.FC<Props> = ({
    connector,
    index,
    modalClose
}) => {

    const { connect, connectors, error, isLoading, pendingConnector, isSuccess } = useConnect();
    const { address, isConnected } = useAccount();

    return (

        <div key={index} className="flex justify-between bg-gray-800 px-5 py-3 rounded-xl">
            <div className="flex gap-3 items-center">
                <WalletModalIconComponent name={connector.name} />
                <div className="text-lg font-normal text-gray-50">{connector.name}</div>
            </div>

            <Button variant='transparent' variantType='button' variantSize='block' onClick={() => {
                connect({ connector });
                modalClose();
            }}>
                Connect
            </Button>
        </div>
    )
}

