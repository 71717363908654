import React, { useState } from 'react';
import Button from "../new-component/button";
import {useNavigate, useParams} from "react-router-dom";

type ItemProps = {
  tokenId?: number;
  itemName: string;
  ethPrice: string;
  usdPrice: string;
  href: string;
  img: string;
  text?: string;
  iconBoolean?: boolean;
  iconOnclick?: string;
  saveIcon?: boolean;
}

const ItemComponent: React.FC<ItemProps> = ({ tokenId, itemName, ethPrice, usdPrice, href, img, text, iconBoolean = false, iconOnclick, saveIcon = false }) => {
  const navigate = useNavigate();
  const { collectionAddress } = useParams();
  const [hovered, setHovered] = useState(false);

  const hoverBlockStyle = {
    opacity: hovered ? 1 : 0,
    transition: 'opacity 0.4s',
  };

  return (
    <li className="z-10" >
      <div className="card">
        <div className="relative" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={() => navigate(`/collections/${collectionAddress}/${tokenId}`)}>
          <img src={img} className="object-cover h-[164px] sm:h-[280px] md:h-[214px] lg:h-[290px] xl:h-[280px] w-full rounded-xl" alt="" />
          {saveIcon && (
          <div className="absolute top-0 bg-gray-800 m-6 p-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
              <path d="M7.65298 13.9149L7.6476 13.9121L7.62912 13.9024C7.61341 13.8941 7.59102 13.8822 7.56238 13.8667C7.50511 13.8358 7.42281 13.7907 7.31906 13.732C7.11164 13.6146 6.81794 13.4425 6.46663 13.2206C5.76556 12.7777 4.82731 12.1314 3.88539 11.3197C2.04447 9.73318 0 7.35227 0 4.5C0 2.01472 2.01472 0 4.5 0C5.9144 0 7.17542 0.652377 8 1.67158C8.82458 0.652377 10.0856 0 11.5 0C13.9853 0 16 2.01472 16 4.5C16 7.35227 13.9555 9.73318 12.1146 11.3197C11.1727 12.1314 10.2344 12.7777 9.53337 13.2206C9.18206 13.4425 8.88836 13.6146 8.68094 13.732C8.57719 13.7907 8.49489 13.8358 8.43762 13.8667C8.40898 13.8822 8.38659 13.8941 8.37088 13.9024L8.3524 13.9121L8.34702 13.9149L8.34531 13.9158C8.13 14.03 7.87 14.03 7.65529 13.9161L7.65298 13.9149Z" fill="#D1D5DB"/>
            </svg>
          </div>
          )}
          <div
            className="absolute bottom-0 p-2 gap-2 w-full items-center justify-center hover-block flex"
            style={hoverBlockStyle}
          >
            <a href={href} rel="noopener noreferrer" className="text-center bg-gray-900 hover:bg-gray-800 text-gray-200 text-sm font-medium rounded py-1.5 w-full">
              Buy Now
            </a>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="flex flex-row justify-between items-center mt-2">
          <div className="text-gray-300 text-base xl:text-lg font-semibold">
            {itemName}
          </div>
          {iconBoolean && (
            <Button variant='icon' variantType='button' onClick={iconOnclick as (() => void) | undefined}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M3 10.4999C3 9.67145 3.67157 8.99988 4.5 8.99988C5.32843 8.99988 6 9.67145 6 10.4999C6 11.3283 5.32843 11.9999 4.5 11.9999C3.67157 11.9999 3 11.3283 3 10.4999Z" fill="#D1D5DB"/>
                <path d="M8.5 10.4999C8.5 9.67145 9.17157 8.99988 10 8.99988C10.8284 8.99988 11.5 9.67145 11.5 10.4999C11.5 11.3283 10.8284 11.9999 10 11.9999C9.17157 11.9999 8.5 11.3283 8.5 10.4999Z" fill="#D1D5DB"/>
                <path d="M15.5 8.99988C14.6716 8.99988 14 9.67145 14 10.4999C14 11.3283 14.6716 11.9999 15.5 11.9999C16.3284 11.9999 17 11.3283 17 10.4999C17 9.67145 16.3284 8.99988 15.5 8.99988Z" fill="#D1D5DB"/>
              </svg>
            </Button>
          )}
        </div>
        <div className="flex flex-col">
          <div className="text-green-500 font-semibold text-base md:text-xl">
            {ethPrice}
          </div>
          <div className="text-white font-semibold text-base md:text-xl">
            {text}
          </div>
          <div className="text-gray-500 text-sm font-medium mt-1">
            {usdPrice}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ItemComponent;
