import React from "react";
import Backgrounds from "../components/backgrounds";
import { Footer } from "../new-component/footer";
import CreateAccountForms from "../new-component/createAccountForms";


export const CreateAccount: React.FC = () => {
  return (
    <Backgrounds>
        <CreateAccountForms/>
      <Footer/>
    </Backgrounds>
  );
};
