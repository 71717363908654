import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

// @ts-ignore
export default function Select({ options , handleSelectClick}) {
  const [selected, setSelected] = useState(options[0]);

  return (
    <Listbox className='relative' value={selected} onChange={setSelected}  as="div">
      {({ open }) => (
        <>
            <Listbox.Button className="relative text-sm inline-block cursor-pointer rounded-md bg-gray-900 py-2 px-4 pr-10 text-left text-gray-50 shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-violet-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-500 transform ${open ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}
                aria-hidden="true"
              />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options className="absolute right-0 top-10 md:top-12 z-40 overflow-auto rounded-lg bg-gray-800 p-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option: { value: string; name: string }) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-700 text-gray-50' : 'text-gray-50',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                    onClick={(event: any) => handleSelectClick(event, option.value)}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate', 'pr-8')}>
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-green-400' : 'text-green-400',
                              'absolute inset-y-0 right-0 flex items-center pr-3'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
        </>
      )}
    </Listbox>
  )
}
