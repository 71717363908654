import React from "react";
import Backgrounds from "../components/backgrounds";
import { Footer } from "../new-component/footer";
import PasswordResetLinkForm from "../new-component/forgotPasswordLinkForms"

export const ForgotPasswordLink: React.FC = () => {
  return (
    <Backgrounds>
       <PasswordResetLinkForm/>
      <Footer/>
    </Backgrounds>
  );
};
