import React from 'react';
import IconCoinbase from "./IconCoinbase";
import IconWalletConnect from "./IconWalletConnect";
import IconMetaMask from "./IconMetamask";

interface IconProps {
  name: string;
}

const WalletModalIconComponent: React.FC<IconProps> = ({
    name
}) => {

  let Output;

  switch ( name ) {
    case 'MetaMask':
      Output = (<IconMetaMask/>);
      break;
    case 'Coinbase Wallet':
      Output = (<IconCoinbase/>);
      break;
    case 'WalletConnect':
      Output = (<IconWalletConnect/>);
      break;
    default:
      Output = (null);
      break;
  }
  return Output;
};

export default WalletModalIconComponent;
