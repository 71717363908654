import React, { useState } from 'react';
import {Attribute} from "../hooks/useGetItem";

type AccordionItemProps = {
  title: string;
  content: React.ReactNode;
  isOpen: boolean;
  toggleAccordion: () => void;
};

const AccordionItem: React.FC<AccordionItemProps> = ({ title, content, isOpen, toggleAccordion }) => (
  <li className={`border-b border-gray-800 relative ${isOpen ? 'open' : ''}`}>
    <div
      className="text-base flex items-center justify-between text-neutral-50 py-7 font-medium w-full cursor-pointer select-none"
      onClick={() => toggleAccordion()}
    >
      <div className="text-base text-neutral-50 font-medium block w-full cursor-pointer select-none">{title}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        className={`${
          isOpen
            ? 'transform rotate-180 transition-transform duration-500 ease-in-out'
            : ''
        }`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.23017 7.20938C5.52875 6.92228 6.00353 6.93159 6.29063 7.23017L10 11.1679L13.7094 7.23017C13.9965 6.93159 14.4713 6.92228 14.7698 7.20938C15.0684 7.49647 15.0777 7.97125 14.7906 8.26983L10.5406 12.7698C10.3992 12.9169 10.204 13 10 13C9.79599 13 9.60078 12.9169 9.45938 12.7698L5.20938 8.26983C4.92228 7.97125 4.93159 7.49647 5.23017 7.20938Z"
          fill="#6B7280"
        />
      </svg>
    </div>
    <div className={`text-base text-gray-500 pb-8 font-normal ${isOpen ? '' : 'hidden'}`}>
      {content}
    </div>
  </li>
);

type DetailsAccordionProps = {
  description: string;
  attributes: Attribute[];
};


const DetailsAccordion: React.FC<DetailsAccordionProps> = ({ description, attributes }) => {
  const [accordionState, setAccordionState] = useState<{ description: boolean; attributes: boolean }>({
    description: true,
    attributes: false,
  });

  const toggleAccordion = (accordionName: keyof typeof accordionState) => {
    setAccordionState({
      ...accordionState,
      [accordionName]: !accordionState[accordionName],
    });
  };

  return (
    <div className="xl:max-w-2xl pb-24">
      <div className="text-white text-2xl font-semibold pb-4 md:text-3xl xl:text-4xl">
        Item's Details
      </div>
      <ul className="accordion">
        <AccordionItem
          title="Description"
          content={description}
          isOpen={accordionState.description}
          toggleAccordion={() => toggleAccordion('description')}
        />
        <AccordionItem
          title="Attributes"
          content={
            <div>
              {
                attributes.map(attribute => (
                    <div className="mb-4 flex rounded-lg border border-gray-900 py-2 px-4 justify-between items-center">
                      <div className="text-base text-gray-500">{attribute.name}</div>
                      <div className="text-white text-base font-medium max-w-[150px] md:max-w-full">{attribute.value}</div>
                    </div>
                ))
              }
            </div>
          }
          isOpen={accordionState.attributes}
          toggleAccordion={() => toggleAccordion('attributes')}
        />
      </ul>
    </div>
  );
};

export default DetailsAccordion;
