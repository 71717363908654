import React, { Fragment } from 'react'
import { Menu, Transition, Listbox } from '@headlessui/react'
import Button from '../new-component/button';
import IconEthereum from "../img/icons/IconEthereum";
import {
  useAccount,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useBalance,
  useNetwork,
  useSwitchNetwork
} from 'wagmi'
import IconMetaMask from "../img/icons/IconMetamask";

export default function WalletComponent() {
  const { address, connector, isConnected } = useAccount();
  const { data: ensAvatar } = useEnsAvatar({ address });
  const { data: ensName } = useEnsName({ address });
  const { disconnect } = useDisconnect();
  const { data, isError } = useBalance({
    address: address,
    watch: true,
  })
  const { chain } = useNetwork()
  const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="text-sm text-gray-200 font-medium items-center flex gap-2 py-2 px-3 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-600 sm:leading-6">
          <IconEthereum/>
          {/*{ensAvatar}*/}
          {data?.formatted} {data?.symbol}
        </Menu.Button>
      </div>

      <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-[274px] origin-top-right rounded-lg bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Listbox>

            <div className="relative">
              <Listbox.Button className="relative w-full cursor-pointer text-xl text-gary-300 font-medium px-5 py-3">
                <div className='flex items-center gap-4'>
                  <IconMetaMask/>
                  <span className="font-normal block truncate gap-2 text-gray-300 text-xl">{address!.substring(0, 6)}...{address!.substring(38, address!.length)}</span>
                </div>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"></span>
              </Listbox.Button>

              <Transition
                  show={true}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                <Listbox.Options className="z-10 w-full py-1 overflow-auto px-1.5 text-base">
                  <div className='border-b border-gray-700 py-0.5 mx-3'></div>
                  <div className="py-1">
                    <Button variant="navLink" variantSize="full" onClick={() => {navigator.clipboard.writeText(address!)}}>Copy address</Button>
                    {/*<Button variant="navLink" variantType="link" to='/' variantSize="full" >Refresh Funds</Button>*/}
                    <Button variant="navLink" variantSize="full" onClick={disconnect} id={'disco'}>Disconnect</Button>
                  </div>
                  <div className='border-b border-gray-700 mx-3'></div>
                  <div className="py-1">
                    {chains.map((x) => (
                        <>
                          <Button variant="navLink" variantSize="full" className={x.id === chain?.id ? 'text-green-500' : 'text-red-500'}>
                            {x.id === chain?.id ? chain!.name : 'Wrong network'}
                          </Button>
                        </>
                    ))}
                  </div>
                </Listbox.Options>
              </Transition>
            </div>

          </Listbox>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
