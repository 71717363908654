import { useAccount, useConnect, useDisconnect, useEnsAvatar, useEnsName, } from 'wagmi'
import Button from '../new-component/button';
import React, { useState } from "react";
import { ParsedProposal } from "../hooks/useParseProposals";
import { ContractAddress } from "../components/search";
import { WalletConnector } from "./walletConnector";

interface Props {
    modalClose: () => void;
}

export const WalletProfile: React.FC<Props> = ({
      modalClose
  }) => {
    const { connect, connectors, error, isLoading, pendingConnector, isSuccess } = useConnect();
    const { address, connector, isConnected } = useAccount();
    // const ensAvatar = useEnsAvatar({ address: address });
    const { data: ensName } = useEnsName({ address: address });
    const { disconnect } = useDisconnect();

    if (isConnected) {
        // @ts-ignore
        return (
            <div>
                {/*<img src={ensAvatar!} alt="ENS Avatar" />*/}
                <div>{ensName ? `${ensName} (${address})` : address}</div>
                {/*<div>Connected to {connector!.name}</div>*/}
                {/*<button onClick={disconnect!}>Disconnect</button>*/}
            </div>
        )
    }

    return (
        <div className="grid gap-4">
            {connectors.map((connector, index) => (

                // {const ensAvatar = useEnsAvatar({ address: address })}

                /*<div key={index} className="flex justify-between bg-gray-800 px-5 py-3 rounded-xl">
                    <div className="flex gap-3 items-center">
                        <div className="text-lg font-normal text-gray-50">{connector.name}</div>
                    </div>

                    <Button variant='transparent' variantType='button' variantSize='block' onClick={() => {
                        connect({ connector });
                        modalClose();
                    }}>
                        Connect
                    </Button>
                </div>*/

                <WalletConnector /*address={address}*/ connector={connector} index={index} modalClose={modalClose}/>

                /*<div>
                    <Button variant='transparent' variantType='button' variantSize='block'
                        isDisabled={!connector.ready}
                        key={connector.id}
                        onClick={() => connect({ connector })}
                    >
                        {connector.name}
                        {!connector.ready && ' (unsupported)'}
                        {isLoading &&
                            connector.id === pendingConnector?.id &&
                            ' (connecting)'}
                    </Button>
                </div>*/
            ))}
            {error && <div>{error.message}</div>}
        </div>
    )
}

