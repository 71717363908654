import React from 'react';

const IconMetaMask = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
            <path d="M22.7866 0.329102L13.4219 7.2844L15.1536 3.18087L22.7866 0.329102Z" fill="#E2761B" stroke="#E2761B" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.16792 0.329102L10.4573 7.35028L8.81028 3.18087L1.16792 0.329102ZM19.4173 16.4515L16.9232 20.2726L22.2597 21.7409L23.7938 16.5362L19.4173 16.4515ZM0.179688 16.5362L1.70439 21.7409L7.04086 20.2726L4.54675 16.4515L0.179688 16.5362Z" fill="#E4761B" stroke="#E4761B" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.73901 9.99502L5.25195 12.2444L10.5508 12.4797L10.3625 6.78561L6.73901 9.99502ZM17.2143 9.99502L13.5437 6.71973L13.4214 12.4797L18.7108 12.2444L17.2143 9.99502ZM7.04019 20.2727L10.2214 18.7197L7.47313 16.5738L7.04019 20.2727ZM13.732 18.7197L16.9225 20.2727L16.4802 16.5738L13.732 18.7197Z" fill="#E4761B" stroke="#E4761B" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.9214 20.2727L13.7308 18.7197L13.9849 20.7997L13.9567 21.675L16.9214 20.2727ZM7.03906 20.2727L10.0038 21.675L9.98495 20.7997L10.2202 18.7197L7.03906 20.2727Z" fill="#D7C1B3" stroke="#D7C1B3" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.0526 15.1999L7.39844 14.4187L9.27138 13.5623L10.0526 15.1999ZM13.902 15.1999L14.6831 13.5623L16.5655 14.4187L13.902 15.1999Z" fill="#233447" stroke="#233447" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.03904 20.2726L7.4908 16.4514L4.54492 16.5361L7.03904 20.2726ZM16.4696 16.4514L16.9214 20.2726L19.4155 16.5361L16.4696 16.4514ZM18.7096 12.2444L13.4202 12.4797L13.9096 15.1997L14.6908 13.562L16.5732 14.4185L18.7096 12.2444ZM7.39669 14.4185L9.27904 13.562L10.0508 15.1997L10.5496 12.4797L5.2508 12.2444L7.39669 14.4185Z" fill="#CD6116" stroke="#CD6116" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.25195 12.2444L7.47313 16.5738L7.39784 14.4185L5.25195 12.2444ZM16.5743 14.4185L16.4802 16.5738L18.7108 12.2444L16.5743 14.4185ZM10.5508 12.4797L10.052 15.1997L10.6731 18.4091L10.8143 14.1832L10.5508 12.4797ZM13.4214 12.4797L13.1672 14.1738L13.2802 18.4091L13.9108 15.1997L13.4214 12.4797Z" fill="#E4751F" stroke="#E4751F" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.9114 15.1999L13.2808 18.4093L13.7326 18.7199L16.4808 16.574L16.5749 14.4187L13.9114 15.1999ZM7.39844 14.4187L7.47373 16.574L10.222 18.7199L10.6737 18.4093L10.0526 15.1999L7.39844 14.4187Z" fill="#F6851B" stroke="#F6851B" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.9567 21.6751L13.9849 20.7998L13.7497 20.5927H10.2014L9.98495 20.7998L10.0038 21.6751L7.03906 20.2727L8.07436 21.1198L10.1732 22.5786H13.7779L15.8861 21.1198L16.9214 20.2727L13.9567 21.6751Z" fill="#C0AD9E" stroke="#C0AD9E" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.7322 18.7198L13.2804 18.4092H10.6734L10.2216 18.7198L9.98633 20.7998L10.2028 20.5927H13.751L13.9863 20.7998L13.7322 18.7198Z" fill="#161616" stroke="#161616" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.1805 7.73616L23.9805 3.89616L22.7852 0.329102L13.7311 7.0491L17.2134 9.99498L22.1358 11.435L23.2275 10.1644L22.7569 9.82557L23.5099 9.13851L22.9264 8.68675L23.6793 8.11263L23.1805 7.73616ZM-0.0195312 3.89616L0.780469 7.73616L0.272234 8.11263L1.02517 8.68675L0.451057 9.13851L1.204 9.82557L0.73341 10.1644L1.81576 11.435L6.73812 9.99498L10.2205 7.0491L1.16635 0.329102L-0.0195312 3.89616Z" fill="#763D16" stroke="#763D16" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.1373 11.435L17.215 9.99502L18.7115 12.2444L16.4809 16.5738L19.4173 16.5362H23.7938L22.1373 11.435ZM6.73969 9.99502L1.81733 11.435L0.179688 16.5362H4.54675L7.47381 16.5738L5.25263 12.2444L6.73969 9.99502ZM13.422 12.4797L13.7326 7.04914L15.1632 3.18091H8.81028L10.222 7.04914L10.5515 12.4797L10.6644 14.1927L10.6738 18.4091H13.2809L13.2997 14.1927L13.422 12.4797Z" fill="#F6851B" stroke="#F6851B" strokeWidth="0.0941176" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default IconMetaMask;
