import React from 'react';

const IconCoinbase = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_499_13650)">
                <path d="M12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0Z" fill="#0052FF"/>
                <path d="M12.0023 16.2188C9.67266 16.2188 7.78359 14.332 7.78359 12C7.78359 9.66797 9.67266 7.78125 12.0023 7.78125C14.0906 7.78125 15.825 9.30469 16.1578 11.2969H20.407C20.0484 6.96562 16.4227 3.5625 12 3.5625C7.34062 3.5625 3.5625 7.34062 3.5625 12C3.5625 16.6594 7.34062 20.4375 12 20.4375C16.4227 20.4375 20.0484 17.0344 20.407 12.7031H16.1555C15.8203 14.6953 14.0906 16.2188 12.0023 16.2188Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_499_13650">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconCoinbase;
