import React from 'react';
import { Fragment,useState } from 'react'
import { Menu, Transition, Listbox } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import Button from '../new-component/button';

interface ModalWalletProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const people = [
  { id: 1, name: '0x50Ba...cA3A' },
  { id: 2, name: '0x34jA...cy5S' },
  { id: 3, name: '0x82vS...u1AS' },
]

const cryptos = [
  { id: 1, name: 'Ethereum', price: '$12,563.00 USD' },
  { id: 2, name: 'Arbitrum', price: '$6,124.00 USD' },
  { id: 3, name: 'Avalanche', price: '$1,124.00 USD' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}



const ModalWallet: React.FC<ModalWalletProps> = ({ title, isOpen, onClose }) => {
  const [selectedPerson, setSelectedPerson] = useState(people[0]);
  const [selectedCrypto, setSelectedCrypto] = useState(cryptos[0]);
  if (!isOpen) return null;

  return (
    <div
      className="fixed px-4 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-950 bg-opacity-50 z-50"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className=" bg-gray-900 p-4 rounded-xl w-full md:w-2/3 lg:w-2/4 xl:w-2/5 2xl:w-2/6 transition-opacity">
        <div className="flex items-center justify-between mb-6">
          <div id="modal-title" className="text-2xl text-gray-50 font-medium">
            {title}
          </div>
          <button onClick={onClose} className="text-gray-500 pr-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z" fill="#D1D5DB"/>
            </svg>
          </button>
        </div>
          <Listbox value={selectedPerson} onChange={setSelectedPerson}>
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-pointer text-xl text-gary-300 font-medium">
                    <div className='flex items-center gap-4'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                        <path d="M22.7866 0.329102L13.4219 7.2844L15.1536 3.18087L22.7866 0.329102Z" fill="#E2761B" stroke="#E2761B" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.16792 0.329102L10.4573 7.35028L8.81028 3.18087L1.16792 0.329102ZM19.4173 16.4515L16.9232 20.2726L22.2597 21.7409L23.7938 16.5362L19.4173 16.4515ZM0.179688 16.5362L1.70439 21.7409L7.04086 20.2726L4.54675 16.4515L0.179688 16.5362Z" fill="#E4761B" stroke="#E4761B" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.73901 9.99502L5.25195 12.2444L10.5508 12.4797L10.3625 6.78561L6.73901 9.99502ZM17.2143 9.99502L13.5437 6.71973L13.4214 12.4797L18.7108 12.2444L17.2143 9.99502ZM7.04019 20.2727L10.2214 18.7197L7.47313 16.5738L7.04019 20.2727ZM13.732 18.7197L16.9225 20.2727L16.4802 16.5738L13.732 18.7197Z" fill="#E4761B" stroke="#E4761B" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.9214 20.2727L13.7308 18.7197L13.9849 20.7997L13.9567 21.675L16.9214 20.2727ZM7.03906 20.2727L10.0038 21.675L9.98495 20.7997L10.2202 18.7197L7.03906 20.2727Z" fill="#D7C1B3" stroke="#D7C1B3" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.0526 15.1999L7.39844 14.4187L9.27138 13.5623L10.0526 15.1999ZM13.902 15.1999L14.6831 13.5623L16.5655 14.4187L13.902 15.1999Z" fill="#233447" stroke="#233447" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.03904 20.2726L7.4908 16.4514L4.54492 16.5361L7.03904 20.2726ZM16.4696 16.4514L16.9214 20.2726L19.4155 16.5361L16.4696 16.4514ZM18.7096 12.2444L13.4202 12.4797L13.9096 15.1997L14.6908 13.562L16.5732 14.4185L18.7096 12.2444ZM7.39669 14.4185L9.27904 13.562L10.0508 15.1997L10.5496 12.4797L5.2508 12.2444L7.39669 14.4185Z" fill="#CD6116" stroke="#CD6116" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.25195 12.2444L7.47313 16.5738L7.39784 14.4185L5.25195 12.2444ZM16.5743 14.4185L16.4802 16.5738L18.7108 12.2444L16.5743 14.4185ZM10.5508 12.4797L10.052 15.1997L10.6731 18.4091L10.8143 14.1832L10.5508 12.4797ZM13.4214 12.4797L13.1672 14.1738L13.2802 18.4091L13.9108 15.1997L13.4214 12.4797Z" fill="#E4751F" stroke="#E4751F" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.9114 15.1999L13.2808 18.4093L13.7326 18.7199L16.4808 16.574L16.5749 14.4187L13.9114 15.1999ZM7.39844 14.4187L7.47373 16.574L10.222 18.7199L10.6737 18.4093L10.0526 15.1999L7.39844 14.4187Z" fill="#F6851B" stroke="#F6851B" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.9567 21.6751L13.9849 20.7998L13.7497 20.5927H10.2014L9.98495 20.7998L10.0038 21.6751L7.03906 20.2727L8.07436 21.1198L10.1732 22.5786H13.7779L15.8861 21.1198L16.9214 20.2727L13.9567 21.6751Z" fill="#C0AD9E" stroke="#C0AD9E" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.7322 18.7198L13.2804 18.4092H10.6734L10.2216 18.7198L9.98633 20.7998L10.2028 20.5927H13.751L13.9863 20.7998L13.7322 18.7198Z" fill="#161616" stroke="#161616" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M23.1805 7.73616L23.9805 3.89616L22.7852 0.329102L13.7311 7.0491L17.2134 9.99498L22.1358 11.435L23.2275 10.1644L22.7569 9.82557L23.5099 9.13851L22.9264 8.68675L23.6793 8.11263L23.1805 7.73616ZM-0.0195312 3.89616L0.780469 7.73616L0.272234 8.11263L1.02517 8.68675L0.451057 9.13851L1.204 9.82557L0.73341 10.1644L1.81576 11.435L6.73812 9.99498L10.2205 7.0491L1.16635 0.329102L-0.0195312 3.89616Z" fill="#763D16" stroke="#763D16" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.1373 11.435L17.215 9.99502L18.7115 12.2444L16.4809 16.5738L19.4173 16.5362H23.7938L22.1373 11.435ZM6.73969 9.99502L1.81733 11.435L0.179688 16.5362H4.54675L7.47381 16.5738L5.25263 12.2444L6.73969 9.99502ZM13.422 12.4797L13.7326 7.04914L15.1632 3.18091H8.81028L10.222 7.04914L10.5515 12.4797L10.6644 14.1927L10.6738 18.4091H13.2809L13.2997 14.1927L13.422 12.4797Z" fill="#F6851B" stroke="#F6851B" stroke-width="0.0941176" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span className="font-normal block truncate gap-2 text-gray-300 text-xl">{selectedPerson.name}</span>
                    </div>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                    <ChevronDownIcon
                      className={`h-5 w-5 text-gray-500 transform ${open ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}
                      aria-hidden="true"
                    />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="w-full pt-3 overflow-auto text-base">
                      {people.map((person) => (
                        <Listbox.Option
                          key={person.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-gray-300' : 'text-gray-300',
                              'relative cursor-pointer select-none py-3'
                            )
                          }
                          value={person}
                        >
                          {({ selected, active }) => (
                            <>
                            <span className={classNames('block truncate', selected ? 'selected-wallet' : '')}>
                              {person.name}
                            </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-green-500' : 'text-green-500',
                                    'absolute inset-y-0 right-0 flex items-center pr-1'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                      <div className='border-b border-gray-700 py-1'></div>
                      <div className="gap-2 grid py-1">
                        <Button variant="navLink" variantType="link" to='/' variantSize="full" >Refresh Funds</Button>
                        <Button variant="navLink" variantType="link" to='/' variantSize="full" >Disconnect</Button>
                      </div>
                      <Listbox value={selectedCrypto} onChange={setSelectedCrypto}>
                        {({ open }) => (
                          <>
                            <div className="relative pb-3 pt-5 px-0.5">
                              

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-10 absolute py-1 bottom-14 rounded w-4/5 ml-7 bg-gray-800 overflow-auto text-sm">
                                  {cryptos.map((crypto) => (
                                    <Listbox.Option
                                      key={crypto.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-gray-600 rounded-lg' : '',
                                          'relative cursor-pointer select-none py-2 pl-2.5 pr-8'
                                        )
                                      }
                                      value={crypto}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span className={classNames('block truncate', selected ? 'selected-wallet' : '')}>
                                            <div className="flex justify-between items-center">
                                              <span className="text-left text-white">{crypto.name}</span>
                                              <span className="text-right text-gray-400">{crypto.price}</span>
                                            </div>
                                          </span>
                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? 'text-green-500' : 'text-green-500',
                                                'absolute inset-y-0 right-0 flex items-center pr-1'
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>

                              <Listbox.Button className="relative w-full text-sm font-normal rounded-md border border-gray-700 cursor-pointer px-3 py-2 focus:ring-2 focus:ring-violet-600">
                                <div className="flex justify-between">
                                  <span className="block truncate text-gray-50">{selectedCrypto.name}</span>
                                  <span className="block truncate mr-5 text-gray-400">{selectedCrypto.price}</span>
                                </div>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon
                                  className={`h-5 w-5 text-gray-500 transform ${open ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}
                                  aria-hidden="true"
                                />
                                </span>
                              </Listbox.Button>

                            </div>
                          </>
                        )}
                      </Listbox>
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>     
      </div>
    </div>
  );
};

export default ModalWallet;