import { useEffect, useState } from "react";

export type Attribute = {
  name: string;
  value: string;
}

export type Item = {
  id: number;
  tokenId: number;
  name: string;
  description: string;
  collectionName: string;
  attributes: Attribute[];
  price: string;
  usdPrice: string;
  status: string;
  logo: string;
};

export function useGetItems(page: number, size: number, sort: string, collectionAddress?: string): Item[] {
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    const getItems = async () => {
      fetch(`https://api.qentare.com/v1/collections/${collectionAddress}/items?page=${page}&size=${size}&sort=${sort}`)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setItems(data)
          })
    };

    getItems();
  }, [page, size, sort, collectionAddress]);

  return items;
}
