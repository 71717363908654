import React, { useState } from "react";
import Backgrounds from "../components/backgrounds";
import { Footer } from "../new-component/footer";
import Containers from "../new-component/containers";
import ItemList from "../new-component/itemList";
import Nav from "../components/nav";
import HeaderItemPage from "../new-component/headerItemPage";
import Button from '../new-component/button';
import Accordion from "../new-component/newAccordion";
import Modal1 from "../new-component/modalMakeOffer";
import Modal2 from "../new-component/modalPlaceBid";
import Modal3 from "../new-component/modalUpdateBid";
import { useParams } from "react-router-dom";
import {ItemState, useGetItem} from "../hooks/useGetItem";
import GoogleMapComponent from "../new-component/googleMapComponent";

export const ItemPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const { collectionAddress, tokenId } = useParams();
  const data = useGetItem(tokenId, collectionAddress)

  const openModal = (type: string) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return data && (
    <Backgrounds>
        <Containers>
        <Nav/>
        <HeaderItemPage
          badgeText={data.collectionName}
          itemName={data.name}
          usdPrice={data.usdPrice}
          price={data.price}
          img={data.logo}
          status={data.status}
          auctionTime="1d 3"
          auctionTitleTime="aaaaa"
          auctionDuration="sss"
          statusPrice="aaaa"
        >
            { data.status !== ItemState.SOLD_OUT ?
          <Button
            id="Item Page Button"
            onClick={() => openModal("Modal1")}
            variant="primary"
            variantSize="full"
            variantType="button"
          >
            Buy Now
          </Button> : '' }
          <Modal1
            titleModal="Make Offer"
            isOpen={isModalOpen && modalType === "Modal1"}
            onClose={closeModal}
            price={data.price}
            badgeText={data.collectionName}
            itemName={data.name}
            img={data.logo}
            statusPrice="Current bid"
          />
          <Button
          variant="primary"
          onClick={() => openModal("Modal2")}
          >
            Place Bid
          </Button>
          <Modal2
            titleModal="Palce Bid"
            isOpen={isModalOpen && modalType === "Modal2"}
            onClose={closeModal}
            statusPrice="Current bid"
            price={data.price}
            badgeText={data.collectionName}
            itemName={data.name}
            img={data.logo}
            auctionTitleTime="Time left"
            auctionTime="1d 3h | Nov 16 at 1:43 PM"
            auctionBids="12"
            auctionBidders="10"
          />
          <Button
            variant="secondary"
            onClick={() => openModal("Modal3")}
          >
            Update Maximum Bid
          </Button>
          <Modal3
            titleModal="Update Bid"
            isOpen={isModalOpen && modalType === "Modal3"}
            onClose={closeModal}
            statusPrice="Current bid"
            price={data.price}
            badgeText={data.collectionName}
            itemName={data.name}
            img={data.logo}
            auctionTitleTime="Time left"
            auctionTime="1d 3h | Nov 16 at 1:43 PM"
            auctionBids="12"
            auctionBidders="10"
          />
        </HeaderItemPage>

        <Accordion
          description={data.description}
          attributes={data.attributes}
        />

        <GoogleMapComponent key={data.name} name={data.name} center={data.center} itemPolygon={data.polygon} collectionPolygon={data.collectionPolygon}/>

        <ItemList
          title={"More From "+ data.collectionName}
          variantSize="large"
          buttonText="View Collection"
          initialItemsToShow={6}
          itemData={8}
          showNumConfig={{ smallScreen: 6, mediumScreen: 6, largeScreen: 6 }}
          sort="id"
          />
        </Containers>
      <Footer />
    </Backgrounds>
  );
};
