import * as React from "react";
import collection, { Collection } from "./collection";
import OwlCarousel from "react-owl-carousel";
import {LightCollection} from "../hooks/useGetGroupedCollections";

interface CollectionHeaderProps {
    id: string;
    items: number;
    type: 'text-on' | 'text-under' | 'full-width';
    collections: LightCollection[]
}


export const MarketPlaceHeaderCollections: React.FC<CollectionHeaderProps> = ({
    id,
    items,
    type,
    collections
}) => {

    return (
        <div id="collections-carousel-header-container" className="bg-gray-950" >
            <OwlCarousel
                className='owl-theme py-0'
                loop={false}
                margin={20}
                center={false}
                nav={false}
                dots={false}
                navText={[
                    '<div class="absolute top-1/2 md:top-0 -left-4 h-full items-center md:flex" style="z-index: 1;"><div role="button" class="prev px-3 py-3 rounded-full bg-gray-900 bg-opacity-80 group" aria-label="prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M10.5 19.5L3 12M3 12L10.5 4.5M3 12H21" stroke="#E5E7EB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></div></div>',
                    '<div class="absolute top-1/2 md:top-0 -right-4 h-full items-center md:flex" style="z-index: 1;"><div role="button" class="next px-3 py-3 rounded-full bg-gray-900 bg-opacity-80 group" aria-label="next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M14.5 3.5L22 12M22 12L14.5 19.5M19 12H4" stroke="#E5E7EB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></div></div>'
                ]}
                navContainer={'#' + id}
                responsive={{
                    '360': {
                        items: 1
                    },
                    '768': {
                        items: 2
                    },
                    '1000': {
                        items: items
                    },
                }}
            >
                { collections.length ?
                    collections.map(collection => (
                        <div>
                            <div className="item">
                                <Collection key={"header-collection-"+ collection.name} type={type} title={collection.name} permalink={collection.permalink} shortDescription={collection.description} items={collection.totalItems} volume={collection.totalVolume} fileUrl={collection.fileUrl}/>
                            </div>
                        </div>
                    )) :
                    <div>
                        <div className="item">
                            <Collection type={type} title="Coming soon" permalink="" shortDescription="" items="" volume="0 ETH" fileUrl="/coming-soon.jpg"/>
                        </div>
                    </div>
                }
            </OwlCarousel>
        </div>
    );
};
