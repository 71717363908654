import React, { useState } from 'react';
import Logo from '../img/Logo (1).svg';
import Input from '../new-component/input';
import Button from '../new-component/button';
import { ReactComponent as EyeIcon } from '../img/eye.svg';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

interface FormValues {
  username: string;
  password: string;
}

interface LoggedInUser {
  id: number;
  accessToken: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  status: string;
  tokenType: string;
}

const SignUpForms: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  // const [loggedInUser, setLoggedInUser] = useState(undefined);

  const navigate = useNavigate();

  const logIn = async (values: FormValues) => {
    fetch('/api/user/v1/auth/sign-in', {
    // fetch('http://localhost:8080/v1/auth/sign-in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
        .then(response => {
          return response.json()
        })
        .then(data => {
          window.localStorage.setItem('loggedInUser', JSON.stringify(data));
          navigate('/');
        })
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.username) errors.username = 'Username is required';
    if (!values.password) errors.password = 'Password is required';

    return errors;
  };

  const onSubmit = (values: FormValues) => {
    logIn(values);
  };

  return (

      <div className="flex min-h-full flex-col justify-center px-4 pt-32 pb-20 md:pt-56 md:pb-64 lg:px-8 bg-gray-950">

        <div className="sm:mx-auto sm:w-full sm:max-w-xs">
          <img className="mx-auto h-8 w-auto" src={Logo} alt="Logo" />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xs">

          <Formik
            initialValues={{
                username: '',
                password: ''
            }}
            validate={validate}
            onSubmit={onSubmit}>

            {({ errors, touched, isValid, handleChange, handleBlur, values }) => (

              <div className="space-y-4">
                <div>
                  <Form>
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium leading-5 text-gray-400">
                        Username
                      </label>
                    </div>
                    <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                      <Field
                          type="text"
                          id="username"
                          name="username"
                          placeholder=""
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`outline-none w-full text-gray-500 text-base font-normal ${errors.username && touched.username ? 'border-red-500' : 'border-gray-300'}`}
                          style={{ background: 'none' }} />
                      <ErrorMessage name="username" component="div" className="text-xs text-red-500" />
                    </div>
                  </Form>
                </div>
                <div>
                  <Form>
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium leading-5 text-gray-400">
                        Password
                      </label>
                    </div>
                    <div className="relative mt-2 flex items-center w-full justify-between rounded-md border border-gray-700 focus:border-violet-600 bg-gray-900 py-1.5 px-2">
                      <Field
                          type="password"
                          id="password"
                          name="password"
                          placeholder=""
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`outline-none w-full text-gray-500 text-base font-normal ${errors.password && touched.password ? 'border-red-500' : 'border-gray-300'}`}
                          style={{ background: 'none' }} />
                      <ErrorMessage name="password" component="div" className="text-xs text-red-500" />
                    </div>
                  </Form>
                </div>

                {/*<div>
                  <Form>
                    <button
                        type="submit"
                        disabled={!isValid}
                        className={`px-4 py-2 text-white bg-blue-500 rounded-md ${!isValid ? 'opacity-50' : ''}`}
                    >
                      Sign In
                    </button>
                  </Form>
                </div>*/}

                <div className="flex justify-center">
                  <Button variant='link' variantType='link' to='/passwords'>
                    Forgot Password?
                  </Button>
                </div>

                <div className="space-y-6 pt-4">
                  <Form>
                    <Button
                        variant='primary'
                        type='submit'
                        variantType='button'
                        variantSize='full'
                        isDisabled={!isValid}>
                      Sign In
                    </Button>
                  </Form>
                  <Button variant='secondary' variantSize='full' variantType='link' to='/sign-up'>
                    Create Account
                  </Button>
                </div>
              </div>

            )}

          </Formik>

          {/*<div className="flex min-h-full flex-col justify-center px-4 pt-32 pb-20 md:pt-56 md:pb-64 lg:px-8 bg-gray-950">
          <Formik
              initialValues={{
                username: '',
                password: ''
              }}
              validate={validate}
              onSubmit={onSubmit}
          >
            {/!*{({ errors, touched, isValid, handleChange, handleBlur, values }) => (*!/}
            <div className="sm:mx-auto sm:w-full sm:max-w-xs">
              <img className="mx-auto h-8 w-auto" src={Logo} alt="Logo" />
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xs">
              <form className="space-y-4" onSubmit={handleSubmit}>

                <Input label='Email' id="username" name="username" type="email" required/>
                <Input label='Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>

                <div className="flex justify-center">
                  <Button variant='link' variantType='link' to='/passwords'>
                    Forgot Password?
                  </Button>
                </div>

                <div className="space-y-6 pt-4">
                  <Button variant='primary' type='submit' variantType='button' variantSize='full'>
                    Sign In
                  </Button>
                  <Button variant='secondary' variantSize='full' variantType='link' to='/sign-up'>
                    Create Account
                  </Button>
                </div>
              </form>
            </div>
            {/!*)}*!/}
          </Formik>

          <Formik
          initialValues={{
            username: '',
            password: ''
          }}
          validate={validate}
          onSubmit={onSubmit}
      >
        {({ errors, touched, isValid, handleChange, handleBlur, values }) => (
            <div className="flex justify-between items-end min-h-full">
              <div>
                <Form>
                    <label htmlFor="username" className="block text-sm font-medium text-gray-600">
                      First name
                    </label>
                    <Field
                        type="text"
                        id="username"
                        name="username"
                        placeholder=""
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`w-full px-3 py-2 border rounded-md ${errors.username && touched.username ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-blue-500`}
                    />
                    <ErrorMessage name="firstName" component="div" className="text-xs text-red-500" />
                </Form>
              </div>
              <div>
                <Form>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                      Password
                    </label>
                    <Field
                        type="password"
                        id="password"
                        name="password"
                        placeholder=""
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`w-full px-3 py-2 border rounded-md ${errors.password && touched.password ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:border-blue-500`}
                    />
                    <ErrorMessage name="password" component="div" className="text-xs text-red-500" />
                </Form>
              </div>
              <div>
                <Form>
                  <button
                      type="submit"
                      disabled={!isValid}
                      className={`px-4 py-2 text-white bg-blue-500 rounded-md ${!isValid ? 'opacity-50' : ''}`}
                  >
                    Sign In
                  </button>
                </Form>
              </div>
            </div>
        )}
      </Formik>*}


      {/*<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xs">
        <form className="space-y-4" onSubmit={handleSubmit}>

          <Input label='Email' id="username" name="username" type="email" required/>
          <Input label='Password' id="password" name="password" type="password" icon={<EyeIcon/>} required/>

          <div className="flex justify-center">
            <Button variant='link' variantType='link' to='/passwords'>
              Forgot Password?
            </Button>
          </div>

          <div className="space-y-6 pt-4">
            <Button variant='primary' type='submit' variantType='button' variantSize='full'>
              Sign In
            </Button>
            <Button variant='secondary' variantSize='full' variantType='link' to='/sign-up'>
              Create Account
            </Button>
          </div>
        </form>
      </div>
          </div>*/}
      </div>

    </div>

  );
};

export default SignUpForms;

