import React, { ReactNode } from 'react';

type InfoCardProps = {
  text: string;
  title: string;
  icon: ReactNode;
  children: ReactNode;
};

const InfoCard: React.FC<InfoCardProps> = ({ title, text, icon, children }) => {
  return (
    <div className="pb-8 md:pb-12 xl:pb-[72px]">
      <div className="flex gap-4 md:gap-5 items-start px-4 pb-4 md:py-4 md:pl-6 md:pr-8 pt-3 rounded-2xl bg-opacity-5 bg-gray-100">
        <div>
          {icon}
        </div>
        <div className="md:flex md:w-full md:items-center md:justify-between md:gap-5">
          <div>
            <div className="text-lg text-white font-semibold whitespace-nowrap">
              {title}
            </div>
            <div className="text-sm font-normal text-gray-300 mt-1">
              {text}
            </div>
          </div>
          <div className="mt-4 md:mt-0">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;