import React from "react";
import { Search } from "../components/search";
import Nav from "../components/nav";
import { TextType, TextCard } from "../components/textCard";
import { FaChevronCircleRight } from "react-icons/fa";
import { Collection } from "../components/collection";
import { Footer } from "../new-component/footer";
import Backgrounds from "../components/backgrounds";
import Containers from "../new-component/containers";


import Button from '../new-component/button';


const TextData: TextType[] = [
  {
    label: "Enter Governor Address",
    text: "Enter the desired Governor contract address, network id, and ensure your wallet is connected to the right network.",
  },
  {
    label: "Connect to contract",
    text: 'The app will search for created proposals. This may take some time depending on your wallets RPC provider.',
  },
  {
    label: "Vote",
    text: "If there are any active proposals, they will appear at the top where they can be selected and voted upon.",
  },
];

export const Home: React.FC = () => {
  return (
    <Backgrounds>
      <Containers>
        <Nav />

        <Button variant='secondary' variantSize='full' variantType='link' to='/CollectionPage'>
           Collection Page
        </Button>
        <br />
        <Button variant='secondary' variantSize='full' variantType='link' to='/ItemPage'>
           Item Page
        </Button>
        <br />
        <Button variant='secondary' variantSize='full' variantType='link' to='/Profile'>
           Profile
        </Button>
        <br />
        <Button variant='secondary' variantSize='full' variantType='link' to='/ChangeEmail'>
          Change Email
        </Button>
        <br />
        <Button variant='secondary' variantSize='full' variantType='link' to='/Setting'>
          Setting
        </Button>
        <br />
        <Button variant='secondary' variantSize='full' variantType='link' to='/marketplaceHome'>
          MarketplaceHome
        </Button>
        <Collection type="text-on" title="Lorem ipsum" shortDescription="dolor sir amet"/>
        <div className="w-full pt-12 flex flex-col items-start">
          <div className="w-4/5 space-y-8">
            <div className="flex flex-col items-start">
              <h2 className="text-2xl font-bold">What is this?</h2>
              <p className="text-left text-white">
                Tally Zero is a simplified, open-source, zero-dependency governance
                front end served via IPFS. Vote on Governor proposals directly
                onchain without the possibility of censorship or downtime.
              </p>
              <p className="pt-4 text-gray-500 text-xs">Learn more</p>
              <div className="flex">
                <i className="text-purple-500">
                  <FaChevronCircleRight />
                </i>
                <a
                  className="text-gray-500"
                  href="https://www.tally.xyz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tally.xyz
                </a>
                <i className="text-purple-500">
                  <FaChevronCircleRight />
                </i>
                <a
                  className="text-gray-500"
                  href="https://github.com/withtally/tally-zero"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Github
                </a>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6 min-w-full">
              {TextData.map((text) => (
                <TextCard key={text.label} label={text.label} text={text.text} />
              ))}
            </div>
            <Search />
          </div>
        </div>
      </Containers>
      <Footer/>
    </Backgrounds>
  );
};
