import { Fragment, useState, useEffect } from 'react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Listbox, Transition } from '@headlessui/react';
import Backgrounds from "../components/backgrounds";
import Containers from "../new-component/containers";
import Nav from "../components/nav";
import { Footer } from "../new-component/footer";
import HeaderProfile from "../new-component/headderProfile";
import { BrowserRouter as Router, Route, NavLink, Routes, Link, useLocation } from "react-router-dom";
import WalletAddress from "../new-component/Setting-WalletAddress";
import ChangePassword from "../new-component/Setting-ChangePassword";
import Notifications from "../new-component/Setting-Notifications";



function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const people = [
  { id: 1, name: 'Wallet Address', to: 'wallet-address' },
  { id: 2, name: 'Change Email', to: '/ChangeEmail' },
  { id: 3, name: 'Change Password', to: 'change-password' },
  { id: 4, name: 'Notifications', to: 'notifications' },
]

const tableData = [
  {
    walletAddress: '0x50Ba...cA3A'
  },
  {
    walletAddress: '0x50Ba...cA3A'
  },
];

const buyerSections = [
  {
    title: "As a buyer - Section 1",
    notifications: [
      { id: "purchaseActivity", title: "Purchase Activity", description: "When you successfully buy an item." },
      { id: "offerExpiration", title: "Offer Expiration", description: "When your offer expires based on the timeframe you set up." },
    ],
  },
  {
    title: "As a buyer",
    notifications: [
      { id: "saleActivity", title: "Sale Activity", description: "When you successfully sell an item." },
      { id: "offerActivity", title: "Offer Activity", description: "When someone sends an offer for one of your items." },
      { id: "offerExpiration", title: "Offer Expiration", description: "When someone’s offer is close to expiration." },
    ],
  },
];


const Setting: React.FC = () => {

  const [selected, setSelected] = useState(people[0]);
  // @ts-ignore
  const loggedInUser = JSON.parse(window.localStorage.getItem('loggedInUser')) || undefined;

  return (
    <Backgrounds>
        <Containers>
          <Nav />
          <HeaderProfile userName={loggedInUser.lastName + " " + loggedInUser.firstName + " (" + loggedInUser.email + ")"} userAddress="0x50Ba...cA3A" />
          <div className="flex flex-col xl:flex-row gap-8 items-start">
            <div className="flex justify-center w-full xl:hidden">
              <Listbox value={selected} onChange={setSelected}>
              {({ open }) => (

                  <div className="relative mt-2">
                    <Listbox.Button className="relative text-sm inline-block cursor-pointer rounded-md bg-gray-900 py-2 px-4 pr-10 text-left text-gray-50 shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-violet-600 sm:text-sm sm:leading-6">
                      <span className="block truncate">{selected.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className={`h-5 w-5 text-gray-500 transform ${open ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}
                        aria-hidden="true"
                      />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute -right-10 md:right-0 top-10 md:top-12 z-40 overflow-auto rounded-lg bg-gray-800 p-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {people.map((person) => (
                          <Listbox.Option
                            key={person.id}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-gray-700 text-gray-50' : 'text-gray-50',
                                'relative cursor-pointer select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <NavLink to={person.to} className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate', 'pr-8 flex items-center gap-3')}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.64222 10.442L11.9994 4.52864L16.3566 10.442C15.4036 10.9715 14.4869 11.5543 13.5551 12.1467C13.0439 12.4716 12.5282 12.7995 11.9995 13.123C11.4708 12.7995 10.9552 12.4717 10.4441 12.1468L10.4441 12.1468C9.51216 11.5544 8.59532 10.9715 7.64222 10.442ZM7.00603 11.8033C7.01206 11.8139 7.01851 11.8244 7.02538 11.8348L11.9994 19.2958L16.9735 11.8348C16.9803 11.8245 16.9868 11.814 16.9928 11.8033C16.1507 12.2762 15.3264 12.8 14.4516 13.3559C13.8778 13.7205 13.2824 14.0988 12.6459 14.4857C12.2488 14.7271 11.7502 14.7271 11.353 14.4857C10.7167 14.0989 10.1213 13.7206 9.54761 13.356C8.67269 12.8001 7.84826 12.2762 7.00603 11.8033ZM5.77731 12.6668C5.31039 11.9664 5.33197 11.0487 5.8313 10.371L11.5969 2.54627C11.7967 2.27507 12.2021 2.27506 12.4019 2.54627L18.1675 10.371C18.6669 11.0487 18.6885 11.9664 18.2215 12.6668L12.4154 21.3759C12.2175 21.6728 11.7813 21.6728 11.5834 21.3759L5.77731 12.6668Z" fill="#D1D5DB"/>
                                  </svg>
                                  {person.name}
                                </NavLink>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-green-400' : 'text-green-400',
                                      'absolute inset-y-0 right-0 flex items-center pr-3'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>

              )}
              </Listbox>
            </div>
            <div className="hidden xl:flex flex-col gap-2 whitespace-nowrap mb-8 md:mb-14 w-80">
              <NavLink className="text-gray-300 text-lg font-medium py-2 px-3 focus:bg-gray-300 focus:bg-opacity-10 focus:rounded-xl focus:text-gray-50 flex items-center gap-3" to="wallet-address">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.64222 10.442L11.9994 4.52864L16.3566 10.442C15.4036 10.9715 14.4869 11.5543 13.5551 12.1467C13.0439 12.4716 12.5282 12.7995 11.9995 13.123C11.4708 12.7995 10.9552 12.4717 10.4441 12.1468L10.4441 12.1468C9.51216 11.5544 8.59532 10.9715 7.64222 10.442ZM7.00603 11.8033C7.01206 11.8139 7.01851 11.8244 7.02538 11.8348L11.9994 19.2958L16.9735 11.8348C16.9803 11.8245 16.9868 11.814 16.9928 11.8033C16.1507 12.2762 15.3264 12.8 14.4516 13.3559C13.8778 13.7205 13.2824 14.0988 12.6459 14.4857C12.2488 14.7271 11.7502 14.7271 11.353 14.4857C10.7167 14.0989 10.1213 13.7206 9.54761 13.356C8.67269 12.8001 7.84826 12.2762 7.00603 11.8033ZM5.77731 12.6668C5.31039 11.9664 5.33197 11.0487 5.8313 10.371L11.5969 2.54627C11.7967 2.27507 12.2021 2.27506 12.4019 2.54627L18.1675 10.371C18.6669 11.0487 18.6885 11.9664 18.2215 12.6668L12.4154 21.3759C12.2175 21.6728 11.7813 21.6728 11.5834 21.3759L5.77731 12.6668Z" fill="#D1D5DB"/>
                </svg>
                Wallet Address
              </NavLink>
              <Link className="text-gray-300 text-lg font-medium py-2 px-3 focus:bg-gray-300 focus:bg-opacity-10 focus:rounded-xl focus:text-gray-50 flex items-center gap-3" to="/ChangeEmail">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21.75 6.74997V17.25C21.75 18.4926 20.7426 19.5 19.5 19.5H4.5C3.25736 19.5 2.25 18.4926 2.25 17.25V6.74997M21.75 6.74997C21.75 5.50733 20.7426 4.49997 19.5 4.49997H4.5C3.25736 4.49997 2.25 5.50733 2.25 6.74997M21.75 6.74997V6.99268C21.75 7.77402 21.3447 8.49941 20.6792 8.90891L13.1792 13.5243C12.4561 13.9693 11.5439 13.9693 10.8208 13.5243L3.32078 8.90891C2.65535 8.49942 2.25 7.77402 2.25 6.99268V6.74997" stroke="#F9FAFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Change Email
              </Link>
              <NavLink className="text-gray-300 text-lg font-medium py-2 px-3 focus:bg-gray-300 focus:bg-opacity-10 focus:rounded-xl focus:text-gray-50 flex items-center gap-3" to="change-password">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M15.75 5.24997C17.4069 5.24997 18.75 6.59312 18.75 8.24997M21.75 8.24997C21.75 11.5637 19.0637 14.25 15.75 14.25C15.3993 14.25 15.0555 14.2199 14.7213 14.1621C14.1583 14.0648 13.562 14.188 13.158 14.592L10.5 17.25H8.25V19.5H6V21.75H2.25V18.932C2.25 18.3352 2.48705 17.7629 2.90901 17.341L9.408 10.842C9.81202 10.438 9.93512 9.84169 9.83785 9.27867C9.7801 8.94443 9.75 8.60071 9.75 8.24997C9.75 4.93626 12.4363 2.24997 15.75 2.24997C19.0637 2.24997 21.75 4.93626 21.75 8.24997Z" stroke="#D1D5DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Change Password
              </NavLink>
              <NavLink className="text-gray-300 text-lg font-medium py-2 px-3 focus:bg-gray-300 focus:bg-opacity-10 focus:rounded-xl focus:text-gray-50 flex items-center gap-3" to="notifications">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M14.8559 17.0817C16.7504 16.857 18.5773 16.4115 20.3102 15.7718C18.8734 14.177 17.9988 12.0656 17.9988 9.74997V9.04916C17.999 9.03278 17.999 9.01638 17.999 8.99997C17.999 5.68626 15.3127 2.99997 11.999 2.99997C8.68531 2.99997 5.99902 5.68626 5.99902 8.99997L5.99883 9.74997C5.99883 12.0656 5.1243 14.177 3.6875 15.7718C5.42043 16.4116 7.24746 16.857 9.14216 17.0817M14.8559 17.0817C13.919 17.1928 12.9656 17.25 11.9988 17.25C11.0322 17.25 10.0789 17.1928 9.14216 17.0817M14.8559 17.0817C14.9488 17.3711 14.999 17.6797 14.999 18C14.999 19.6568 13.6559 21 11.999 21C10.3422 21 8.99902 19.6568 8.99902 18C8.99902 17.6797 9.04921 17.3711 9.14216 17.0817" stroke="#D1D5DB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Notifications
              </NavLink>
            </div>
            <Routes>
              <Route path="wallet-address" element={<WalletAddress tableData={tableData}/>} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="notifications" element={<Notifications buyerSections={buyerSections}/>} />
            </Routes>
          </div>

        </Containers>
      <Footer/>
    </Backgrounds>
  );
};

export default Setting;
