import React, { ReactNode } from 'react';
import Search from "../new-component/searchForm";
import Select from "../new-component/select";

const name = [
  { id: 1, name: '0x50Ba...cA3A' },
  { id: 2, name: '0x60Ba...cA3A' },
  { id: 3, name: '0x70Ba...cA3A' },
  { id: 4, name: '0x80Ba...cA3A' },
];

const variant = [
  { id: 1, name: 'Price low to high' },
  { id: 2, name: 'Price high to low' },
  { id: 3, name: 'Recently listed' },
  { id: 4, name: 'Most Viewed' },
];


interface Props {
  children?: ReactNode;
}

const ItemsNotFound: React.FC<Props> = ({children}) => {
  return (
    <div className="">
      <div className="flex items-center flex-col md:flex-row gap-4">
        <Search/>
        {/*<div className="flex items-center w-full justify-between">*/}
        {/*  /!*<Select variant={name}/>*!/*/}
        {/*  /!*<Select variant={variant}/>*!/*/}
        {/*</div>*/}
      </div>
      <div className="flex flex-col items-center py-12">
        <div className="text-2xl font-medium text-white">
          No items found here
        </div>
        <div className="inline-block pt-8">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ItemsNotFound;
